import Axios from "axios"
import { Link,useNavigate,useLocation } from "react-router-dom";
import { useEffect,useState } from "react";
import InnerCarouselComponent  from './innercarousel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function HomeComponent(props) {
  let age = '';
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [render,setRender] = useState(0);
  let userId = parseInt(localStorage.getItem('userID'));
  let userGender = localStorage.getItem('userGender');
  const { register, handleSubmit, formState: { errors } } = useForm();
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  let userTransaction  = parseInt(localStorage.getItem('userTransaction'));
  let userPaid = parseInt(localStorage.getItem('userPaid'));
  const [userProfile,setUserProfile] = useState({
    id: 0,matri_id: "",fname : "",lname : "",email : "",status : 0,plan :0,
    contact : 0,view : 0,gender: 0,paidmember : 0,plan_end_date : "",pic : "",
    interestsent : 0,interestreceived : 0,messagesent : 0,messagereceived : 0,
    profilecomplete : 0,prefferedlocation:[],prefferedcaste:[],prefferedoccupation:[],
    prefferedtoungue :[],martialstatus:[],withphoto:[],profileview:[],paidmembers:[],
    recentprofiles:[],countprefferedLocation : 0,countprefferedCaste: 0,countprefferedOccupation:0,
    countprefferedToungue:0,countmartialStatus:0,countwithPhoto:0
  });
  const [update,setUpdate] = useState(0);
  const [user,setUser] = useState({});
  
  const getAge = (birthDate) => {
     age = Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)
     return age;
  }

  useEffect(() => { 
    Axios.post(Constants.API_URL+"profiledetailpage",{
      api_token : userToken,profile_id : userId
    }).then((response)=>{
      setUser(response.data.data);  
    
    });
  },[userId,userToken]);

  const sendInterest = (e,partID,genderID) => {  
    if(genderID===userGender){
      navigate('/home',{state:{title:true,error:true,message:t('access_is_denied')}});
    }else{
      if(userPaid===0 || userTransaction===0){
          navigate('/memberplan',{state:{title:true,error:true,message:(userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))}})
        }else{
          Axios.post(Constants.API_URL+"addsendinterest",{
            api_token : userToken, 
            profile_id : partID
          }).then((response)=>{
            setUpdate(update + 1);
            if(response.data.error===true) toast.error(response.data.message);
            else toast.success(response.data.message);
          });
      }
    }
  }

   
  
  useEffect(() => { 
    const getUserData = () => {
      Axios.post(Constants.API_URL+"userdetaildata",{
        api_token : userToken
      }).then((response)=>{
        localStorage.setItem('userID',response.data.data.id);
        localStorage.setItem('userViewedContact',response.data.data.view);
        localStorage.setItem('userContactCount',response.data.data.contact);
        localStorage.setItem('userPaid',response.data.data.paidmember);
        localStorage.setItem('userGender',response.data.data.gender);
        localStorage.setItem('userPhotoPrivacy',response.data.data.photo_privacy);
        localStorage.setItem('userLiveChat',response.data.data.livechat);
        localStorage.setItem('userTransaction',response.data.data.transaction);
        localStorage.setItem('userPhoto',Constants.IMAGE_URL+"pictures/"+response.data.data.pic);
        setUserProfile(response.data.data);
        setRender(1);
      });
   }
    getUserData();
  },[render,userToken]);


  const onSubmit = (data) => {
    if(data.searchid!==""){
      navigate('/searchbyid',{state:{searchid:data.searchid,data:null}});
    }
  } 

  const handleChangeSubmit = (e) => {
    e.preventDefault();
  }

  useEffect(() => {
    if(location.state!==null){
      var title = location.state.title;
      if(title===true){
        if(location.state.message!==""){
            if(location.state.error===true) toast.error(location.state.message);
            else toast.success(location.state.message);
          }
        if(location.state.title) navigate(location.pathname, { replace: true });
      } 
    }
  },[location,navigate])

  const printProfile = (e) => {
    e.preventDefault();
    navigate('/printprofile',{state:{data:user}});
  }

  const maritalStatusMap = {
    1: 'Never Married',
    2: 'Divorced',
    3: 'Separated',
    4: 'Widow',
    5: 'Widower',
    
};

const getMaritalStatus = (status) => {
    return maritalStatusMap[status] || 'Unknown';
};





    return (
      <>
      {render===0 && 
      <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
        <Skeleton />
      </SkeletonTheme>}
      <div className="container mt-5" style={{'height':'auto'}}>
        <ToastContainer />
  			  <div className="row" style={{'marginTop':'-15px'}}>
  			    <div className="col-xl-3 col-md-4 d-sm-none d-md-block">
  			      <div className="card gtHRigDetCard mb-3"  style={{'margin':'0px'}}>
  			        <div className="card-img-top">
                  {userProfile.gender===1 && <img alt={userProfile.matri_id} src={userProfile.pic!=="" ? Constants.IMAGE_URL+"pictures/"+userProfile.pic : require('../assets/images/no_image_groom.png')} className="img-fluid img-shadow" />}
  			          {userProfile.gender===2 && <img alt={userProfile.matri_id} src={userProfile.pic!=="" ? Constants.IMAGE_URL+"pictures/"+userProfile.pic : require('../assets/images/no_image_bride.png')} className="img-fluid img-shadow" />}
  			          <ul className="list-group">
  			            <li className="list-group-item">{t('name')} : {userProfile.fname +" "+ userProfile.lname}  </li>
  			            <li className="list-group-item">{t('member_id')} : {userProfile.matri_id}</li>
  			            {userPaid===1  && 
                      <>
                      <li className="list-group-item">{t('total_data_viewed')} :{userProfile.view}</li>
                      <li className="list-group-item">{t('total_contact_count')} : {userProfile.contact}</li>
                      <li className="list-group-item">{t('plan_expiry_date')} :{userProfile.plan_end_date}</li>
                      </>}
  			            <li className="list-group-item"><Link to="/uploadphoto">{t('add_photo')}</Link></li>
                    <li className="list-group-item"><Link to="/myprofile">{t('edit_profile')}</Link></li>
  			            <li className="list-group-item"><a href="/#" onClick={(e) => printProfile(e)}>{t('print_profile')}</a></li>
  			          </ul>
  			        </div>
  			      </div>
  			      <div className="gtLeftBox card mb-3 d-none d-sm-none d-md-none d-lg-block" style={{'margin':'0px'}}>
  			        <div className="gtLeftBoxHead card-header">
  			          <h4>{t('messages')}</h4>
  			        </div>
  			        <div className="gtLeftBoxBody">
  			          <Link to="/inbox">{t('inbox')} <span className="badge float-right">{userProfile.messagereceived}</span></Link>
  			          <Link to="/messagesent">{t('sent')} <span className="badge float-right">{userProfile.messagesent}</span></Link>
  			        </div>
  			      </div>                                                         
  			      <div className="gtLeftBox card mb-3 d-none d-sm-none d-md-none d-lg-block"  style={{'margin':'0px'}}>
  			        <div className="gtLeftBoxHead card-header">
  			          <h4>{t('express_interest')}</h4>
  			        </div>
  			        <div className="gtLeftBoxBody">
  			          <Link to="/interestreceive">{t('inbox')}<span className="badge float-right">{userProfile.interestreceived}</span></Link>
  			          <Link to="/interestsent">{t('sent')}<span className="badge float-right">{userProfile.interestsent}</span></Link>
  			        </div>
  			      </div>   
  			      <div className="gtLeftBox card mt-3 mb-3" >
  			        <div className="gtLeftBoxHead card-header">
  			          <h4>{t('search_by_id')}</h4>
  			        </div>
  			        <div className="gtLeftBoxBody pl-3 pr-3 pt-3 pb-3">
  			          <form onSubmit={handleSubmit(onSubmit)} onChange={(e) => handleChangeSubmit(e)} className="mt-4">                
  			            <div className="row">
  			              <div className="form-group col-xl-12 col-md-12">
                        <input
                         className="form-control"
                          type="text"
                          {...register("searchid", {
                            required: true,
                          })}
                          />     
                          {errors.searchid?.type==="required" && <span className="error-msg">{t('search_by_id_is_required')}</span>}                          
  			              </div>
  			              <div className="form-group  col-xl-12 col-md-12 mb-0">
  			                <input className="btn gtBtnLSear btn-block" type="submit" value={t('submit')} />                 
  			              </div>
  			            </div>
  			          </form>             
  			        </div>
  			      </div>          
  			    </div>
  			    <div className="col-xl-6 col-md-8" >
  			      <div className="card gtHTCard"  style={{'margin':'0px'}}>
  			        <div className="card-body">
  			          <h4>{t('complete_details')}</h4>
  			            <h5 className="mt-4">{t('profile_completeness')}:&nbsp;&nbsp;<b>{userProfile.profilecomplete}%</b></h5>
  			          <div className="progress">
  			            <div className="progress-bar bg-warning" role="progressbar" style={{width:userProfile.profilecomplete+"%"}} aria-valuenow="39" aria-valuemin="0" aria-valuemax="100">
  			            </div>
  			          </div>
  			        </div>
  			      </div>
              {userPaid===0 &&
                <>
                <div className="gtHomeBox card mt-3" style={{'margin':'0px'}}>
                <div className="gtHomeBoxHead">{t('recently_join')}</div>
                <div className="gtHomeBoxBody">
                </div>
                <Link to="/recentjoin" className="gtHomeBoxFooter">{t('view_all')}
                </Link>          
              </div>
              <div className="gtHomeBox card mt-3" style={{'margin':'0px'}}>
                <div className="gtHomeBoxHead" >{t('paid_members')}</div>
                <div className="gtHomeBoxBody">
                </div>  
                <Link to="/paidmembers" className="gtHomeBoxFooter">
                {t('view_all')}
                </Link>        
              </div>
              </>
              }
  			      {userPaid===1 &&
                <>
                <div className="gtHomeBox card mt-3" style={{'margin':'0px'}}>
                <div className="gtHomeBoxHead">{t('recently_join')}</div>
                <div className="gtHomeBoxBody">
                  {userProfile.recentprofiles.length > 0  &&  userProfile.recentprofiles.map((user) => (
                    <div className="row" key={user.ud_id}>
                    <div className="col-xl-4 col-sm-6 col-md-4  mb-3">
                      <div className="text-center">
                        {(((user.ud_photo_privacy===2 && userPaid===1) || (user.ud_photo_privacy===1  && userToken!==null)) && user.ud_photo_privacy!==0) && 
                          <img alt={user.ud_matri_id} style={{'minWidth':'100px','minHeight':'100px'}} 
                          src={user.ud_pic_small!=="" ? Constants.IMAGE_URL+"pictures/"+user.ud_pic_small : (user.ud_gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png'))} 
                          className="img-fluid img-shadow" />
                        }
                        {(user.ud_photo_privacy===0 || (user.ud_photo_privacy===2 && userPaid===0)) && 
                          <img alt={user.ud_matri_id} style={{'minWidth':'100px','minHeight':'100px'}} 
                          src={user.ud_gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png')} 
                          className="img-fluid img-shadow" />
                        }
                        {(userPaid===0 || userToken===null) && 
                          <Link to={userToken!==null ? "/memberplan" : "/login"}
                          state={{title:true,error:true,message:(userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))}}>
                            <div className="gtSMresultName">{user.ud_firstname}</div>
                          </Link>
                        }
                        {(userPaid===1 && userToken!==null) && 
                           <Link to={`/viewprofile/${user.ud_id}`}>
                            <div className="gtSMresultName">
                            {user.ud_firstname + user.ud_lastname}</div>
                          </Link>
                        }
                      <h6>({user.ud_matri_id})</h6>    
                      <div className="gtSMresultDet">
                        {getAge(user.ud_dob)} {t('year')},<br />
                        {getMaritalStatus(user.ud_marital)},<br />
                        {user.rel_name},{user.caste_name},<br/>{user.state_name},{user.ctry_name}                       
                      </div>
                        {(user.interest_user_id!==userId || user.interest_status===null) &&  
                          <button style={{'color':'#fff'}}  onClick={(e) => sendInterest(e,user.ud_id,user.ud_gender)} className="btn gtBtnExpSM mt-2"><i className="fas fa-heart mr-2"></i>{t('send_interest')}
                        </button>}
                        {(user.interest_user_id===userId && user.interest_status===0) && <button style={{'color':'#fff'}} className="btn gtBtnExpSM mt-2"><i className="fas fa-heart mr-2"></i>{t('request_sent')}
                        </button>}
                        {(user.interest_user_id===userId && user.interest_status>0)  && <Link to={`/composemessage/${user.ud_id}`} style={{'color':'#fff'}} className="btn gtBtnExpSM mt-2"><i className="fas fa-envelope mr-2"></i>{t('send_message')}
                        </Link>}
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
                <Link to="/recentjoin" className="gtHomeBoxFooter">{t('view_all')}
                </Link>          
              </div>
              <div className="gtHomeBox card mt-3" style={{'margin':'0px'}}>
                <div className="gtHomeBoxHead" >{t('paid_members')}</div>
                <div className="gtHomeBoxBody">
                {userProfile.paidmembers.length > 0  &&  userProfile.paidmembers.map((user) => (
                  <div className="row" key={user.ud_id}>
                    <div className="col-xl-4 col-sm-6 col-md-4  mb-3">
                      <div className="text-center">
                        {(((user.ud_photo_privacy===2 && userPaid===1) || (user.ud_photo_privacy===1 && userToken!==null)) && user.ud_photo_privacy!==0) && 
                          <img alt={user.ud_matri_id} style={{'minWidth':'100px','minHeight':'100px'}} 
                          src={user.ud_pic_small!=="" ? Constants.IMAGE_URL+"pictures/"+user.ud_pic_small : (user.ud_gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png'))} 
                          className="img-fluid img-shadow" />}
                        {(user.ud_photo_privacy===0 || (user.ud_photo_privacy===2 && userPaid===0)) && 
                          <img alt={user.ud_matri_id} style={{'minWidth':'100px','minHeight':'100px'}} 
                          src={user.ud_gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png')} 
                          className="img-fluid img-shadow" />}
                        {(userPaid===0 || userToken===null) && 
                          <Link to={userToken!==null ? "/memberplan" : "/login"}
                          state={{title:true,error:true,message:(userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))}}>
                            <div className="gtSMresultName">{user.ud_firstname}</div>
                          </Link>}
                        {(userPaid===1 && userToken!==null) && 
                           <Link to={`/viewprofile/${user.ud_id}`}>
                            <div className="gtSMresultName">
                            {user.ud_firstname + user.ud_lastname}</div>
                          </Link>}
                        <h6>({user.ud_matri_id})</h6>    
                        <div className="gtSMresultDet">
                          {getAge(user.ud_dob)} {t('year')},<br />
                          {getMaritalStatus(user.ud_marital)},<br />
                          {user.rel_name},{user.caste_name},<br/>{user.state_name},{user.ctry_name}                       
                        </div>
                        {(user.interest_user_id!==userId || user.interest_status===null) &&  
                          <button style={{'color':'#fff'}}  onClick={(e) => sendInterest(e,user.ud_id,user.ud_gender)} className="btn gtBtnExpSM mt-2"><i className="fas fa-heart mr-2"></i>{t('send_interest')}
                        </button>}
                        {(user.interest_user_id===userId && user.interest_status===0) && <button style={{'color':'#fff'}} className="btn gtBtnExpSM mt-2"><i className="fas fa-heart mr-2"></i>{t('request_sent')}
                        </button>}
                        {(user.interest_user_id===userId && user.interest_status>0)  && <Link to={`/composemessage/${user.ud_id}`} style={{'color':'#fff'}} className="btn gtBtnExpSM mt-2"><i className="fas fa-envelope mr-2"></i>{t('send_message')}
                        </Link>}
                      </div>
                    </div>
                  </div>
                  ))}
                </div>  
                <Link to="/paidmembers" className="gtHomeBoxFooter">
                {t('view_all')}
                </Link>        
              </div>
              </>
              }
  			      <div className="gtHomeBox card mt-3 mb-3">
  			        <div className="gtHomeBoxHead">{t('profile_viewed_by')}</div>
  			        <div className="gtHomeBoxBody">
  			          {userProfile.profileview.length > 0  &&  userProfile.profileview.map((user) => (
                    <div className="row" key={user.ud_id}>
                    <div className="col-xl-4 col-sm-6 col-md-4  mb-3">
                      <div className="text-center">
                       {(((user.ud_photo_privacy===2 && userPaid===1) || (user.ud_photo_privacy===1  && userToken!==null))  && user.ud_photo_privacy!==0) && 
                        <img alt={user.ud_matri_id} style={{'minWidth':'100px','minHeight':'100px'}} 
                        src={user.ud_pic_small!=="" ? Constants.IMAGE_URL+"pictures/"+user.ud_pic_small : (user.ud_gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png'))} 
                        className="img-fluid img-shadow" />}
                      {(user.ud_photo_privacy===0 || (user.ud_photo_privacy===2 && userPaid===0)) &&
                        <img alt={user.ud_matri_id} style={{'minWidth':'100px','minHeight':'100px'}} 
                        src={user.ud_gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png')} 
                        className="img-fluid img-shadow" />}
                      {(userPaid===0 || userToken===null || userTransaction===0) && 
                        <Link to={userToken!==null ? "/memberplan" : "/login"}
                        state={{title:true,error:true,message:(userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))}}>
                          <div className="gtSMresultName">{user.ud_firstname}</div>
                        </Link>}
                      {(userPaid===1 && userToken!==null) && 
                         <Link to={`/viewprofile/${user.ud_id}`}>
                          <div className="gtSMresultName">
                          {user.ud_firstname + user.ud_lastname}</div>
                        </Link>}
                      <h6>({user.ud_matri_id})</h6>    
                      <div className="gtSMresultDet">
                        {getAge(user.ud_dob)} {t('year')},<br />
                        {getMaritalStatus(user.ud_marital)},<br />
                        {user.rel_name},{user.caste_name},<br/>{user.state_name},{user.ctry_name}                       
                      </div>
                        {(user.interest_user_id!==userId || user.interest_status===null) &&  
                          <button style={{'color':'#fff'}}  onClick={(e) => sendInterest(e,user.ud_id,user.ud_gender)} className="btn gtBtnExpSM mt-2"><i className="fas fa-heart mr-2"></i>{t('send_interest')}
                        </button>}
                        {(user.interest_user_id===userId && user.interest_status===0) && <button style={{'color':'#fff'}} className="btn gtBtnExpSM mt-2"><i className="fas fa-heart mr-2"></i>{t('request_sent')}
                        </button>}
                        {(user.interest_user_id===userId && user.interest_status>0)  && <Link to={`/composemessage/${user.ud_id}`} style={{'color':'#fff'}} className="btn gtBtnExpSM mt-2"><i className="fas fa-envelope mr-2"></i>{t('send_message')}
                        </Link>}
                      </div>
                    </div>
                  </div>
                  ))}
  			        </div>
  			        <Link to="/profileview" className="gtHomeBoxFooter">
  			        View All
  			        </Link>
  			      </div>
  			    </div>
  			    <div className="col-xl-3 offset-xl-0 col-md-8 offset-md-4">
  			      <div className="gtRightPro card mb-3 pb-3" style={{'margin':'0px'}}>
  			        <div className="gtRightProHead">{t('with_photo')}</div>
  			        <div className="gtRightProBody">
  			          <div id="owl1" style={{'opacity':'1','display':'block'}} className="owl-carousel owl-theme">
  			            <div className="owl-wrapper-outer">
  			              <div className="owl-wrapper" style={{'width': 'auto', 'left': '0px', 'display': 'block', 'transition': 'all 1000ms ease 0s', 'transform': 'translate3d(0px, 0px, 0px)'}}>
  			                <div className="owl-item" style={{'width':'auto'}}>
  			                  <div className="owl-controls clickable">
                          {userProfile.withphoto.length > 0 ? <InnerCarouselComponent count={userProfile.countwithPhoto} userToken={userToken} userPaid={userPaid} data={userProfile.withphoto} update={update} setUpdate={setUpdate}/> : ""}
                          </div>
  			                </div>
  			              </div>
  			            </div>
  			          </div>
  			        </div>
  			      </div>
  			      <div className="gtRightPro card mb-3 pb-3" style={{'margin':'0px'}}>
                <div className="gtRightProHead">{t('preferred_location')}</div>
                <div className="gtRightProBody">
                  <div id="owl1" style={{'opacity':'1','display':'block'}} className="owl-carousel owl-theme">
                    <div className="owl-wrapper-outer">
                      <div className="owl-wrapper" style={{'width': 'auto', 'left': '0px', 'display': 'block', 'transition': 'all 1000ms ease 0s', 'transform': 'translate3d(0px, 0px, 0px)'}}>
                        <div className="owl-item" style={{'width':'auto'}}>
                          <div className="owl-controls clickable">
                          {userProfile.prefferedlocation.length > 0 ? <InnerCarouselComponent count={userProfile.countprefferedLocation} userToken={userToken} userPaid={userPaid} data={userProfile.prefferedlocation} update={update} setUpdate={setUpdate}/> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gtRightPro card mb-3 pb-3" style={{'margin':'0px'}}>
                <div className="gtRightProHead">{t('preferred_caste')}</div>
                <div className="gtRightProBody">
                  <div id="owl1" style={{'opacity':'1','display':'block'}} className="owl-carousel owl-theme">
                    <div className="owl-wrapper-outer">
                      <div className="owl-wrapper" style={{'width': 'auto', 'left': '0px', 'display': 'block', 'transition': 'all 1000ms ease 0s', 'transform': 'translate3d(0px, 0px, 0px)'}}>
                        <div className="owl-item" style={{'width':'auto'}}>
                          <div className="owl-controls clickable">
                          {userProfile.prefferedcaste.length > 0 ? <InnerCarouselComponent count={userProfile.countprefferedCaste} userToken={userToken} userPaid={userPaid} data={userProfile.prefferedcaste} update={update} setUpdate={setUpdate}/> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gtRightPro card mb-3 pb-3" style={{'margin':'0px'}}>
                <div className="gtRightProHead">{t('preferred_occupation')}</div>
                <div className="gtRightProBody">
                  <div id="owl1" style={{'opacity':'1','display':'block'}} className="owl-carousel owl-theme">
                    <div className="owl-wrapper-outer">
                      <div className="owl-wrapper" style={{'width': 'auto', 'left': '0px', 'display': 'block', 'transition': 'all 1000ms ease 0s', 'transform': 'translate3d(0px, 0px, 0px)'}}>
                        <div className="owl-item" style={{'width':'auto'}}>
                          <div className="owl-controls clickable">
                          {userProfile.prefferedoccupation.length > 0 ? <InnerCarouselComponent count={userProfile.countprefferedOccupation} userToken={userToken} userPaid={userPaid} data={userProfile.prefferedoccupation} update={update} setUpdate={setUpdate}/> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gtRightPro card mb-3 pb-3" style={{'margin':'0px'}}>
                <div className="gtRightProHead">{t('preferred_mother_toungue')}</div>
                <div className="gtRightProBody">
                  <div id="owl1" style={{'opacity':'1','display':'block'}} className="owl-carousel owl-theme">
                    <div className="owl-wrapper-outer">
                      <div className="owl-wrapper" style={{'width': 'auto', 'left': '0px', 'display': 'block', 'transition': 'all 1000ms ease 0s', 'transform': 'translate3d(0px, 0px, 0px)'}}>
                        <div className="owl-item" style={{'width':'auto'}}>
                          <div className="owl-controls clickable">
                          {userProfile.prefferedtoungue.length > 0 ? <InnerCarouselComponent count={userProfile.countprefferedToungue} userToken={userToken} userPaid={userPaid} data={userProfile.prefferedtoungue} update={update} setUpdate={setUpdate}/> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gtRightPro card mb-3 pb-3" style={{'margin':'0px'}}>
                <div className="gtRightProHead">{t('preferred_martial_status')}</div>
                <div className="gtRightProBody">
                  <div id="owl1" style={{'opacity':'1','display':'block'}} className="owl-carousel owl-theme">
                    <div className="owl-wrapper-outer">
                      <div className="owl-wrapper" style={{'width': 'auto', 'left': '0px', 'display': 'block', 'transition': 'all 1000ms ease 0s', 'transform': 'translate3d(0px, 0px, 0px)'}}>
                        <div className="owl-item" style={{'width':'auto'}}>
                          <div className="owl-controls clickable">
                          {userProfile.martialstatus.length > 0 ? <InnerCarouselComponent count={userProfile.countmartialStatus} userToken={userToken} userPaid={userPaid} data={userProfile.martialstatus} update={update} setUpdate={setUpdate}/> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  			    </div>
  			  </div>
  			</div>
      </>
     )
}