import React, { useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link,useNavigate,useLocation } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.min.css' 
import 'owl.carousel/dist/assets/owl.theme.default.min.css'
import Axios from "axios"
import { useForm } from 'react-hook-form';
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constants from "../services/Constants";

export default function IndexComponent(){
  let userId = localStorage.getItem("userId");
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  let userPaid = parseInt(localStorage.getItem('userPaid'));
  let userGender = localStorage.getItem('userGender');
  let gender = (userGender!==null) ? (userGender===1 ? "Male" : "Female") : 0;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit,setValue, formState: { errors } } = useForm(); 
  const [datas,Setdatas] = useState([]);
  const [loading,setLoading] = useState(0);
  const [successstories,setSuccessStories] = useState([]);
  const [inputerrors,setInputerrors]  = useState(0);
  let userTransaction  = localStorage.getItem('userTransaction') ? parseInt(localStorage.getItem('userTransaction')) : null;
  let noofuser = 5;
  const banner = Constants.IMAGE_URL+localStorage.getItem('banner');
  const mystyle = {
    marginTop : '-15px',
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%,rgba(0,0,0,0) 100%), url(${banner})`,
    backgroundSize: 'cover',
    minHeight: '550px'
  }


  if(window.innerWidth >= 320 && window.innerWidth <= 360){
    noofuser = 1;
  }
  if(window.innerWidth >= 361 && window.innerWidth <= 768){
    noofuser = 2;
  }
  if(window.innerWidth >= 769 && window.innerWidth <= 1200){
    noofuser = 3;
  }
  if(window.innerWidth >= 1201){
    noofuser = 4;
  }



  const sendInterest = (e,userPartnerID,userPartnerGender) => { 
    if(userPartnerGender===gender || userPartnerID===userId){
      navigate(location.pathname,{state:{title:true,error:true,message:t('access_is_denied')}});
    }else{
      if(!userToken){
        navigate('/login',{state:{title:true,error:true,message:t('please_login_to_continue')}});
      }else{
        if(userPaid===0 || userTransaction===0){
          navigate('/memberplan',{state:{title:true,error:true,message:(userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))}})
        }else{
          Axios.post(Constants.API_URL+"addsendinterest",{
            api_token : userToken,profile_id : userPartnerID
          }).then((response)=>{
            setLoading(loading + 1);
            if(response.data.error===true) toast.error(response.data.message);
            else toast.success(response.data.message);
          });
        }
      }
    }
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = Constants.IMAGE_URL+"couple.png";
  }

  const addDefaultGroomBride = (e,gender) => {
      e.target.src = Constants.IMAGE_URL+"no_image_bride.png";
    if(gender==="Male"){
      e.target.src = Constants.IMAGE_URL+"no_image_groom.png";
    }
  }

  const onSubmit = (data) => {
    Axios.post(Constants.API_URL+"register",{
      ud_phone:data.username
      }).then((response)=>{
          setInputerrors(0);
          if (response.data.data.id===0) setInputerrors(data.username);
          else navigate('/verify',{state:{title:true,message:t('otp_sent_success'),otp:response.data.data.otp,mobile:data.username}}  );
      }).catch((err) => {
        console.log(err);
      })
  };

  const handleLogin = (e) => {
    setValue('username',e.target.value.replace(/\D/g,''));
    setInputerrors(0);
  }

  useEffect(() => {
    if(location.state!==null){
      var title = location.state.title;
      if(title===true){
        if(location.state.message!==""){
            if(location.state.error===true) toast.error(location.state.message);
            else toast.success(location.state.message);
        }
        if(location.state.title) navigate(location.pathname, { replace: true });
      } 
    }
  },[location,navigate]);

  useEffect(() => {
    const getData = () => {
      let url;
      if(userToken!==null)  url = "recentprofilesandstories/"+userId+"/"+userToken;
      else url = "recentprofilesandstories";
      Axios.get(Constants.API_URL+url,{
      }).then((response)=>{
        setLoading(1);
        if(response.data.data.recentprofiles.length > 0)  Setdatas(response.data.data.recentprofiles);
        if(response.data.data.successstories.length > 0)  setSuccessStories(response.data.data.successstories);
      }).catch((err) => {
        setLoading(1);
      })
    }
    getData();
  },[loading,userId,userToken])



  return (
      <div>
        {(loading===0) && 
          <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
            <Skeleton />
          </SkeletonTheme>}
        <ToastContainer />
        <div className="w3layouts-banner" style={mystyle} id="home">
          <div className="container">
            <div className="col-md-offset-1 col-md-9 finder-block find-section">
              <div className="finder-caption">
               
                {/* <p>{t('over')}<strong>{t('no_of_wedding_order')}</strong>{t('for_special_date_and_matches')}</p> */}
              </div>              
            </div>
          <div className="clearfix"></div>
        </div>
      </div>
      
      <div className="w3l_find-soulmate text-center">
        <h3 className="mb-2">{t('find_your_soulmate')}</h3>
        <div className="section-title-img">
          <img src={require('../assets/images/flower.png')} alt={"ProfileImage"} />       
        </div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-4">
              <Link className="scroll" to={userId ? "/#" : "/register"} 
              state={{title:true,error:true,message:(userId ? '' : t('please_register_to_continue'))}}>
                <div className="col-md-10 w3_soulgrid">
                  <i className="  fas fa-sign-in-alt"></i>
                  <h3>{t('sign_up')}</h3>
                  <p>{t('signup_for_free')}</p>
                </div>
              </Link>
              <Link className="scroll" to="/search">
                <div className="col-md-10 w3_soulgrid">
                  <i className="fa fa-search" aria-hidden="true"></i>
                  <h3>{t('search')}</h3>
                  <p>{t('search_for_free')}</p>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
                <div className="col-md-10 img-tag-lv mar" >
                  <img alt={"search"} src={require('../assets/images/p13.png')} height="435px" />
                </div>
            </div>
            <div className="col-md-4">
              <Link className="scroll" to={userId ? "/newmatches" : "/login"}
              state={{title:true,error:true,message:(userId ? '' : t('please_login_to_continue'))}}>
                <div className="col-md-10 w3_soulgrid">
                  <i className="fa fa-users" aria-hidden="true"></i>
                  <h3>{t('connect')}</h3>
                  <p>{t('connect_your_perfect_match')}</p>
                </div>
              </Link>
              {(userPaid===0 || userToken===null) && 
                <Link className="scroll" to={userToken!==null ? "/memberplan" : "/login"} 
                state={{title:true,error:true,message:(isNaN(userTransaction)) ? (userTransaction!==0 ? t('please_contact_support') : t('please_add_a_membership')) : t('please_login_to_continue')}}>
                  <div className="col-md-10 w3_soulgrid">
                    <i className="fas fa-comments"></i>
                    <h3>{t('interact')}</h3>
                    <p>{t('become_a_member')}</p>
                  </div>
                </Link>
              }
              {userPaid===1 && 
                <Link className="scroll" to="/messagesent">
                  <div className="col-md-10 w3_soulgrid">
                    <i className="fas fa-comments"></i>
                    <h3>{t('interact')}</h3>
                    <p>{t('become_a_member')}</p>
                  </div>
                </Link>
              }
            </div>
          </div>  
          <div className="clearfix"> </div>
        </div>
      </div>
      
     
        <div className="agile-assisted-service text-center">
          <h4 className="mb-2">{t('assisted_service')}</h4>
           <div className="section-title-img">
              <img src={require('../assets/images/flower-leaf.png')} alt={"Matrimony"} />       
            </div>
          <p>{t('our_relationship_managers')}</p>
          <a href="assisted_services.html">{t('know_more')}</a>
        </div>
        <div className="w3layouts_featured-profiles">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-wrap">
                  <div className="wpo-about-item">
                    <div className="wpo-about-img">
                      <img src={require('../assets/images/matri.jpg')} alt={"Matrimony"} />
                    </div>  
                  </div>
                  <div className="about-single-item">
                  </div>
                  <div className="ab-shape">
                    <img src={require('../assets/images/flower-straight.png')} alt={"Matrimony"} />
                  </div>                    
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text">
                  <div className="wpo-about-icon">
                    <div className="icon">
                      <img src={require('../assets/images/flower-small.png')} alt={"Matrimony"} />                           
                    </div>
                  </div>
                  <div className="wpo-about-icon-content">
                    <h2>{t('we_help_statge')}</h2>
                    <p>{t('india_fastest_growing')}</p>
                    <ul>
                      <li>{t('going_to_cities')}</li>
                      <li>{t('variation_of_passages')}</li>
                      <li>{t('making_look_readable_spoken_english')}</li>
                    </ul>                       
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}