import React, { useEffect,useState } from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Constants from "../services/Constants";
import { useForm } from 'react-hook-form';
import { Link as Scroll } from 'react-scroll';

export default function MemberPlanComponent() {
  const { register,handleSubmit, formState: { errors } } = useForm();
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  let userId = localStorage.getItem('userID');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [getMemberPlan,setMemberPlan] = useState([]);
  const [buyedUser,setBuyedUser] = useState(0);
  const [plan,setPlan] = useState({id:0,amount:0,name:''});

  const onSubmit = (data) => {
      Axios.post(Constants.API_URL+"buymemberplan",{
         api_token : userToken,id : plan.id,
         note : data.note , image : data.image[0]
      },{ headers: { "content-type": "multipart/form-data"  }}).then((response)=>{
         if(response.data.data) 
          navigate('/success',{state:{title:true,error:false,message:response.data.message}});
         else 
          navigate('/success',{state:{title:true,error:true,message:response.data.message}});
      }).catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if(state!==null){
      var title = location.state.title;
      if(title===true){
        if(location.state.message!==""){
          if(location.state.error===true) toast.error(location.state.message);
          else toast.success(location.state.message);
        }
        navigate(location.pathname, { replace: true });
      } 
    }
    let url = "getmemberplan";
    if(userToken!==null)  url = "getmemberplan/"+userId;
      Axios.get(Constants.API_URL+url).then((response)=>{
        if (response.data.data){
          response.data.data.forEach((data) => {
            if(data.buyedPlan===true) setBuyedUser(1);
          });
          setMemberPlan(response.data.data);
        } 
      }).catch((err) => {
        console.log(err);
      })
    },[location,navigate,state,userId,userToken]);

    const setPlanDetails = (getSelectPlan) => {
      setPlan({id:getSelectPlan.id,amount:getSelectPlan.amount,name:getSelectPlan.name});
    }

    useEffect(() => {},[plan]);

    return(
        <div className="container mt-5">
          <ToastContainer />
          <div className="row">
            <div className="col-xl-10  offset-xl-1 bg-white">
              <h3 className="gtPageTitle">{t('membership_plan')}</h3>
                <p className="gtPageSubTitle">{t('membership_plans_description')}</p>
                  <div className="row mt-3">
                    {getMemberPlan.map((getPlan,index) => (
                      <div key={getPlan.id} className="gtMemPlan col-xl-12 col-md-12 mb-3">
                        <div className="row">
                          <div className="col-xl-3 col-lg-3 gtMemPName">
                            <h4>{getPlan.name}</h4>
                            <h5>Rs.{getPlan.amount}/-</h5>
                          </div>
                          <div className="col-xl-2 col-lg-2 col-sm-6 gtMemPDetail">
                            <p>{t('duration')}</p>
                            <h4> {getPlan.duration}</h4>
                          </div>
                          <div className="col-xl-2 col-lg-2 col-sm-6 gtMemPDetail">
                            <p>{t('contact_view')}</p>
                            <h4> {getPlan.contact}  </h4>
                          </div>
                          <div className="col-xl-2 col-lg-2 col-sm-6 gtMemPDetail">
                            <p>{t('livechat')}</p>
                            <h4> {getPlan.livechat===1 ? 'Yes' : 'No'}  </h4>
                          </div>
                          <div className="col-xl-3 col-lg-3 gtMemPSelect">
                            {(userToken!==null && getPlan.buyedPlan===true) && 
                              <button
                              className="btn btnMemPlanSel" style={{'width':'100%'}}>
                              {t('subscribed')}</button>}
                            {userToken===null && 
                              <button  style={{'width':'100%'}} className="btn btnMemPlanSel" 
                              onClick={() => navigate('/login',{state:{title:true,error:true,message:t('please_login_to_continue')}})}>{t('subscribe')}</button>}
                            {(userToken!==null && getPlan.buyedPlan===false) && 
                              <Scroll disabled={buyedUser} to={buyedUser ? '' :  "section1"} smooth={true} duration={500}
                               style={{'width':'100%','color':'#fff','opacity': buyedUser ? '0.6' : '1','cursor': buyedUser ? 'not-allowed' : 'pointer'}} 
                                onClick={(e) => setPlanDetails(getPlan)} className="btn btnMemPlanSel">{t('subscribe')}</Scroll>}
                          </div> 
                        </div>
                      </div>
                    ))}
                   <section id="section1" className="col-12 card mb-3 p-2" style={{'visibility':`${(plan.id && !buyedUser) ? 'revert' : 'hidden'}`}}>
                      <div className="gtProBoxBody"  style={{'display':`${(plan.id && !buyedUser) ? 'block' : 'none'}`}}>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <div className="row">
                              <div className="col-sm-12 col-md-5 col-lg-5 gtProTxt" style={{'textAlign':'center'}}>
                                <img alt="Google Pay" width="400px" height="100%" src={require('../assets/images/g-pay.jpg')} />
                              </div>
                              <div className="col-sm-12 col-md-7 col-lg-7 p-5 gtProTxtDet ">
                                <label className='mt-2'>Plan Name : <b>{plan.name!=='' ? plan.name : ''}</b></label>
                                <label>Plan Amount : <b>{plan.amount ? 'Rs:'+plan.amount+'/-' : ''}</b></label> 
                                <form  onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                  <label>Note <span className="required">*</span></label>
                                  <textarea className="form-control" {...register('note',{required:true})} />     
                                  {errors.note?.type==="required" && <p style={{'position' : 'revert'}} className="error mt-2">Please provide notes for transaction details</p>}
                                  <label className="mt-2">Image</label>
                                  <input {...register('image')} className="form-control" type="file" />
                                  <button className="btn btnRegFsubmit btn-block text-center mt-4" type="submit">Submit</button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
    )
}

