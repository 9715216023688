import React, { useEffect,useState,useCallback } from 'react'
import { useForm,Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Select from 'react-select';
import Constants from "../services/Constants";
import $ from "jquery";

export default function UserReligionInfoComponent({userBasicInfo,userCountryData,issubmit,setIsSubmit}){
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const { handleSubmit:handleSubmit2,getValues ,setValue,clearErrors, control, formState: { errors } } = useForm({mode : "onChange",defaultValue:{
  	ud_country :"",ud_state : "",ud_city: ""
  }});
	const [getCountryData,setgetCountryData] = useState([]);
  const [getState,setgetState]  = useState([]);
  const [getCity,setgetCity]  = useState([]);

  const handleCountryChange = useCallback((val) => {
		if(val!==''){
			if(val!==getValues('ud_country')){
				setValue('ud_state','');
				// setValue('ud_city','');
			}
			setValue('ud_country',val);
			Axios.get(Constants.API_URL+"location/"+val+"/0/0").then((response)=>{
				if(response.data.data){
					setgetState(response.data.data);
				}
			});
		}
	},[getValues,setValue])

	const handleStateChange = useCallback((val) => {
		if(val!==''){
			clearErrors("ud_state");
			setValue('ud_state',val);
			Axios.get(Constants.API_URL+"location/"+getValues('ud_country')+"/"+val+"/0").then((response)=>{
				if(response.data.data){
					setgetCity(response.data.data);
				}
			});
		}
	},[clearErrors,getValues,setValue])

	function handleCityChange(e){
		setValue('ud_city',e.value)
		clearErrors("ud_city");
	}

	const onLocSubmit = (data) => {
  	Axios.post(Constants.API_URL+"postmyprofile",{
  		api_token : userToken,ud_country : data.ud_country,ud_state : data.ud_state,
  		ud_city_other : data.ud_city_other
  	}).then((response)=>{
    	setIsSubmit('');
      setIsSubmit(response.data.error+'/'+response.data.message);
      $(".closeLocModal").click();
    }).catch((err) => {
    	console.log(err);
    })
  }

  useEffect(() => {
  	setgetCountryData(userCountryData);
  },[userCountryData]);


	useEffect(() => {
		if(userBasicInfo!=null){
			setValue('ud_country',userBasicInfo.ud_country);
  		setValue('ud_state',userBasicInfo.ud_state);
  		setValue('ud_city_other',userBasicInfo.ud_city_other);
  		handleCountryChange(userBasicInfo.ud_country);
  		handleStateChange(userBasicInfo.ud_state);
		}
	},[userBasicInfo,setValue,handleCountryChange,handleStateChange])

	return (
			<div className="modal fade gtEditForm" id="locationInfoModal" tabIndex="-1" role="dialog" aria-labelledby="locationInfoModal" aria-hidden="true" data-backdrop="static">
			    <div className="modal-dialog" role="document">
			      <div className="modal-content">
			        <div className="modal-header text-center">
			          <div className="col-12">
			            <h5 className="modal-title" id="exampleModalLabel">Location Information
			            <button type="button" className="close closeLocModal" data-dismiss="modal" aria-label="Close">
			                <span aria-hidden="true">×</span>
			            </button>
			            </h5>
			          </div>
			        </div>
			        <div className="modal-body">
			          <div className="row">
			            <form className="form-horizontal" onSubmit={handleSubmit2(onLocSubmit)}>     
			              <div className="container">
			                <div className="row">
			                  <div className="col-xl-12">
			                    <div className="form-group">
			                      <label htmlFor="Userdetails_ud_country" className="required">Country <span className="required">*</span>
			                      </label>              
			                      {getCountryData && (
	                        	<Controller
					                   name="ud_country"
					                   control={control}
					                   rules = {{required:true}}
					                   render={({ field: { onChange, value, name, ref } }) => (
					                     <Select
					                      inputRef={ref}
					                      placeholder ={t('select_any_one')}
					                      className="mb-4"
					                      value={getCountryData.find((c) => c.value === value) || ''}
					                      options={getCountryData}
					                      defaultValue={Constants.prompt}
					                      onChange={(e) => handleCountryChange(e.value)} 
					                     />
					                   )} 
				                 	/>)}     
				                 	{errors.ud_country?.type==="required" && <p className="error">{t('country_cannot_be_blank')}</p>}                    
			                    </div>
			                  </div>
			                  <div className="col-xl-12">
			                    <div className="form-group">
			                      <label htmlFor="Userdetails_ud_state" className="required">State <span className="required">*</span></label> 
			                      {getState && (
	                        	<Controller
					                   name="ud_state"
					                   control={control}
					                   rules = {{required:true}}
					                   render={({ field: { onChange, value, name, ref } }) => (
					                     <Select
					                      inputRef={ref}
					                      placeholder ={t('select_any_one')}
					                      className="mb-4"
					                      value={getState.find((c) => c.value === value) || ''}
					                      options={getState}
					                      defaultValue={Constants.prompt}
					                      onChange={(e) => handleStateChange(e.value)} 
					                     />
					                   )} 
				                 	/>)}     
				                 	{errors.ud_state?.type==="required" && <p className="error">{t('state_cannot_be_blank')}</p>}                    
			                    </div>
			                  </div>
			                  <div className="col-xl-12">
			                    <div className="form-group">
								<label htmlFor="Userdetails_ud_city_other" className="required">City <span className="required">*</span></label>              
									
									<Controller
									name="ud_city_other"
									control={control}
									render={({ field }) => (
										<input  {...field}
											className="form-control"
										/>
									)}
									rules={{
										required: true,
										maxLength: 32,
										pattern: /^[a-zA-Z]+$/
									}}

									/>

				                 	{errors.ud_city_other?.type==="required" && <p className="error">{t('city_cannot_be_blank')}</p>}                    
			                    </div>
			                  </div>
			                  <div className="col-xl-12">
			                    <div className="form-group text-center">
			                      <input className="btn btnRegFsubmit" name="location" type="submit" value="Update" />            
			                    </div>
			                  </div>
			                </div>
			              </div>
			            </form>        
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
		)
	}
	
