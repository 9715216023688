import React, { useState,useEffect } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Constants from "../services/Constants";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SearchComponent() {
  	const location = useLocation();
  	const navigate = useNavigate();
		const { t } = useTranslation();
		let state = location.state;
		let userGender = localStorage.getItem('userGender');
		let userToken = localStorage.getItem('userToken');
		let gender = (userGender!==null) ? (userGender===1 ? 2 : 1) : 0;
		const {  handleSubmit } = useForm();
		const { register: register2, handleSubmit : handleSubmit2, formState: { errors: errors2 } } = useForm();
		const [subCaste,setSubCaste] = useState("");
		const [city,setCity] = useState("");
		const [getCaste,setgetCaste] = useState([]);
		const [getSubCaste,setgetSubCaste] = useState([]);
		const [getState,setgetState]  = useState([]);
		const [getCity,setgetCity]  = useState([]);
		const [religionID,setreligionID]  = useState("");
		const [casteID,setcasteID]  = useState("");
		const [countryID,setcountryID]  = useState("");
		const [stateID,setstateID]  = useState("");
		const [Type,setType] = useState((gender!==1) ? gender : "");
		const [ascAge,setAscAge] = useState("");
		const [descAge,setDescAge] = useState("");
		const [ascHeight,setAscHeight] = useState("");
		const [descHeight,setDescHeight] = useState("");
		const [martialStatus,setMartialStatus] = useState("");
		const [inputErrors,setInputErrors] = useState(0);
		const [getReligionData,setgetReligionData] = useState([]);
		const [getCountryData,setgetCountryData] = useState([]);
		const [getOccupationData,setOccupationData] = useState([]);
		const [getMotherToungueData,setgetMotherToungue] = useState([]);
		const [getEducationData,setgetEducationData] = useState([]);
		const [inputValue,setInputValues] = useState({ 
			matri_id: "",gender : "",asc_age :"",desc_age: "",
			asc_height:"",desc_height:"",marital_status : "",
			religion :"",caste : "",subcaste : "",country :"" ,dhosa :"",
			state :"",city : "", occupation : "",mother_tongue : "",education : ""});
    const [multiCity,setMultiCity] = useState([]);

		const onSubmit = (data) => {
			if(Type!=="" && ascAge!=="" && descAge!==""  && martialStatus!==""
				&& religionID!=="" && casteID!=="" && inputValue.mother_tongue!==""){
				setInputErrors(0);
				navigate('/searchbyid',{state:{data:inputValue}});
			}
		};

		const setSubmit = () => {
			
			setInputErrors(1);
			setInputValues({...inputValue,matri_id: '',gender : Type,asc_age : ascAge,desc_age : descAge,
        asc_height : ascHeight,desc_height : descHeight, marital_status : martialStatus,religion : religionID,
        caste : casteID, subcaste : subCaste,country : countryID,state : stateID , city : city,
        mother_tongue : inputValue.mother_tongue,education : inputValue.education,
        occupation : inputValue.occupation,dhosa : inputValue.dhosa});
		}

		useEffect(() => {
			Axios.get(Constants.API_URL+"getdropdowndata").then((response)=>{
					if(response.data.data){
					  setgetReligionData(response.data.data.getReligion);
					  setgetCountryData(response.data.data.getCountry);
					  setOccupationData(response.data.data.getOccupation);
					  setgetMotherToungue(response.data.data.getMotherToungue);
					  setgetEducationData(response.data.data.getEducation);
					}
				}).catch(errors => {
						console.log(errors);
				});
		},[])

		useEffect(() => {
		},[inputErrors]);

		useEffect(() => {
				if(state!==null){
						if(state.title===true){
							if(state.error===true) toast.error(state.message);
							navigate(location.pathname, { replace: true });
						}
				}
		});
		
		const onSubmitSearch = (data) => {
			if(data.searchid!==""){
				navigate('/searchbyid',{state:{searchid:data.searchid,data:null}});
			}
	  };

		let loadLocations = (e) => {
				// e.preventDefault();
		}

		function handleReligionChange(e) {
			if(e.value!==''){
				setreligionID(e.value);
				Axios.get(Constants.API_URL+"religion/"+e.value+"/0/0").then((response)=>{
					if(response.data.data.length > 0){
						setgetCaste(response.data.data);
					}else{
						setgetCaste([]);
						setgetSubCaste([]);
					}
				});
			}
		}

		function handleCasteChange(e) {
			if(e.value!==''){
				setcasteID(e.value);
				Axios.get(Constants.API_URL+"religion/"+religionID+"/"+e.value+"/0").then((response)=>{
						setgetSubCaste(response.data.data);
				});
			}
		}

		function handleCountryChange(e) {
			if(e.value!==''){
				setcountryID(e.value);
				Axios.get(Constants.API_URL+"location/"+e.value+"/0/0").then((response)=>{
					if(response.data.data.length > 0){
						setgetState(response.data.data);
					}else{
						setgetState([]);
						// setgetCity([]);
					}
				});
			}
		}

		function handleStateChange(e) {
			if(e.value!==''){
				setstateID(e.value);
				Axios.get(Constants.API_URL+"location/"+countryID+"/"+e.value+"/0").then((response)=>{
						// setgetCity(response.data.data);
				});
			}
		}


 


	function handleCityChange(e) {
		let value = "";
		setMultiCity(e);
		value = e;
		setCity(value);
	  }
	  
	
		return(
			<div className="container mt-5">
				<ToastContainer />
				<div className="row">
					<div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2">
						<ul className="nav nav-pills mb-3 nav-fill gtSerPill" id="pills-tab" role="tablist">
							<li className="nav-item">
								<a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">{t('advanced_search')}</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">{t('search_by_id')}</a>
							</li>
						</ul>
						<div className="clearfix"></div>
						<div className="tab-content gtSerBody" id="pills-tabContent">
							<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
								<h3>{t('advanced_search')}</h3>
								<p>{t('advance_search_description')}</p>
								<form className="mt-2" onChange={loadLocations} onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" id="advancedsearch-form">    
									<div className="form-group row">
										<div className="col-xl-4">
											<label className="mt-2">{t('gender')}<span className="required">*</span></label>
										</div>
										<div className="col-xl-8">
												<Select placeholder={t('select_any_one')} className="mt-2" onChange={(e) => setType(e.value)}
												isDisabled={(userToken!==null && Type!=="") ?  true : false}
												value={Type!==null ? Constants.getUserType.filter(obj => obj.value === Type) : Constants.prompt} 
												 options = {Constants.getUserType} defaultValue={Constants.prompt}>
												</Select>  
												{(inputErrors===1 && Type==="") && <span className="error-msg text-left">{t('gender_is_required')}</span>}
										</div>
									</div>
									<div className="form-group row">
										<div className="col-xl-4">
											<label className="mt-2">{t('age')} <span className="required">*</span>
											</label>                
										</div>
										<div className="col-xl-8">
											<div className="row">
												<div className="col-xl-6 col-6">
												 <Select className="mt-2" onChange={(e) => setAscAge(e.value)} options = {Constants.getAgeAsc} defaultValue={Constants.prompt}>
													</Select> 
													{(inputErrors===1 && ascAge==="") && <span className="error-msg text-left">{t('please_select_any_one')}</span>}                                    
												</div>
												<div className="col-xl-6 col-6">
													<Select className="mt-2" onChange={(e) => setDescAge(e.value)} options = {Constants.getAgeDesc} defaultValue={Constants.prompt}>
													</Select>     
													{(inputErrors===1 && descAge==="") && <span className="error-msg text-left">{t('please_select_any_one')}</span>}                                  
												</div>
											</div>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-xl-4">
											<label className="mt-2">{t('height')}</label>                
										</div>
										<div className="col-xl-8">
											<div className="row">
												<div className="col-xl-6 col-lg-6 col-12">
													<Select className="mt-2"  onChange={(e) => setAscHeight(e.value)}  options = {Constants.getHeightAsc} defaultValue={Constants.prompt}>
													</Select> 
												</div>
												<div className="col-xl-6 col-lg-6 col-12">
													<Select className="mt-2"  onChange={(e) => setDescHeight(e.value)}  options = {Constants.getHeightDesc} defaultValue={Constants.prompt}>
													</Select>               
												</div>
											</div>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-xl-4">
											<label  className="mt-2">{t('martial_status')}
											<span className="required">*</span></label>    
										</div>
										<div className="col-xl-8">
											<Select className="mt-2" onChange={(e) => setMartialStatus(e.value)}  options = {Constants.getMartialStatus}  defaultValue={Constants.prompt}>
											</Select> 
											{(inputErrors===1 && martialStatus==="") && <span className="error-msg text-left">{t('martialstatus_is_required')}</span>}                                           
										</div>
									</div>
									<div className="form-group row">
										<div className="col-xl-4">
											<label className="mt-2">{t('religion')}<span className="required">*</span></label>      
										</div>
										<div className="col-xl-8">
											<Select className="mt-2" onChange={(e) => handleReligionChange(e)}  options = {getReligionData} defaultValue={Constants.prompt}>
											</Select>                 
											{(inputErrors===1 && religionID==="") && <span className="error-msg text-left">{t('religion_is_required')}</span>}                                           
										</div>
									</div>
									<div className="form-group row">
									<div className="col-xl-4">
										<label className="mt-2">{t('caste')}<span className="required">*</span></label>                     
									</div>
									<div className="col-xl-8">
										<Select value={getCaste.length > 0 ? getCaste.filter(obj => obj.value === casteID) : ''} placeholder={t('select_any_one')}
									  className="mt-2" onChange={(e) => handleCasteChange(e)}   options = {getCaste}  
									  defaultValue={Constants.prompt}>
										</Select>                               
										{(inputErrors===1 && casteID==="") && <span className="error-msg text-left">{t('caste_cannot_be_blank')}</span>}                                           
									</div>
								</div>
								
								<div className="form-group row">
									<div className="col-xl-4">
										<label className="mt-2">{t('country')}</label>             
									</div>
									<div className="col-xl-8">
										<Select value={getCountryData.length > 0 ? getCountryData.filter(obj => obj.value === countryID) : ''} placeholder={t('select_any_one')}
										 className="mt-2" onChange={(e) => handleCountryChange(e)} options = {getCountryData} defaultValue={Constants.prompt}>
										</Select> 
									</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4">
										<label className="mt-2">{t('state')}</label>                
									</div>
									<div className="col-xl-8">
										<Select value={getState.length > 0 ? getState.filter(obj => obj.value === stateID) : ''} placeholder={t('select_any_one')}
										 className="mt-2" onChange={(e) => handleStateChange(e)} options = {getState} defaultValue={Constants.prompt}>
										</Select> 
									</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4">
										<label className="mt-2">{t('city')}</label>                
									</div>
									<div className="col-xl-8">
									

								<input
								type="text"
								name="ud_city_other"
								placeholder={t('select_any_one')}
								className="form-control"
								onChange={(e) => handleCityChange(e.target.value)}
								value={multiCity}
								/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4">
										<label className="mt-2">{t('mother_toungue')}<span className="required">*</span></label>   
									</div>
									<div className="col-xl-8">
										<Select className="mt-2" onChange={(e) => setInputValues(previousState => { return {...previousState, mother_tongue : e.value} })}
										 options={getMotherToungueData} defaultValue={Constants.prompt}>
										</Select>                                                   
										 {(inputErrors===1 && inputValue.mother_tongue==="") && <span className="error-msg text-left">{t('mother_toungue_cannot_be_blank')}</span>}                                           
									</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4">
										<label className="mt-2">{t('education')}</label>                
									</div>
									<div className="col-xl-8">              
										 <Select className="mt-2" onChange={(e) => setInputValues(previousState => { return {...previousState, education : e.value} })}
										  options={getEducationData} defaultValue={Constants.prompt}>
										</Select>           
									</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4">
										 <label className="mt-2">{t('occupation')}</label>     
									</div>
									<div className="col-xl-8">
										<Select className="mt-2" onChange={(e) => setInputValues(previousState => { return {...previousState, occupation : e.value} })} 
										 options={getOccupationData} defaultValue={Constants.prompt}>
										</Select>                      
									</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4">
										<label className="mt-2">{t('dhosa')}</label>              
									</div>
									<div className="col-xl-8 mt-2">
										<span>
											<label>
												<input name="dhosa" onClick ={(e) => setInputValues({...inputValue,dhosa : e.value})} type="radio" value="1" className="mt-2" />&nbsp; Yes
											</label>
											<br />
											<label>
												<input name="dhosa" onClick ={(e) => setInputValues({...inputValue,dhosa : e.value})} type="radio" value="2" className="mt-2" />&nbsp; No
											</label>
											<br />
											<label>
												<input name="dhosa"  onClick ={(e) => setInputValues({...inputValue,dhosa : e.value})} type="radio" value="3" className="mt-2" />&nbsp; Does Not Matter
											</label>
										</span>   
									</div>
								</div>
								<div className="form-group row mt-3">
									<div className="col-xl-12 text-center">
										<input onClick={setSubmit} className="btn btnRegFsubmit" name="advancedsearch" type="submit" value={t('submit')} />                 
									</div>
								</div>
							</form>
						</div>
						<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
							<div className="tab-pane fade active show" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
								<h3>{t('search_by_id')}</h3>
								<p>{t('enter_matri_id_for_search')}</p>
								<form key={2} onSubmit={handleSubmit2(onSubmitSearch)} className="mt-2" > 
									<div className="form-group row">
										<div className="col-xl-4 text-center">
											<label className="mt-2" htmlFor="Userdetails_ud_matri_id">{t('member_id')}</label>              
										</div>
										<div className="col-xl-8" style={{'paddingLeft':'0'}}>
											<input className="form-control"  type="text"
											{...register2("searchid", 
												{
													required: true, 
													
												}
											)} />
											{errors2.searchid?.type==="required" && <p style={{textAlign:'left'}} className="error" id="left-0">{t('search_by_id_is_required')}</p>}   
										</div>
									</div>
									<div className="form-group row mt-2 center-768">
										<div className="col-xl-12 btn-center" style={{'paddingLeft':'0'}}>
											<input className="btn btnRegFsubmit"  type="submit" value={t('submit')} />              
										</div>
									</div>
								</form>        
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		)
}