import React, { useEffect,useState,useCallback } from 'react'
import { useForm,Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Select from 'react-select';
import Constants from "../services/Constants";
import useReligion from "../hook/useReligion";
import $ from "jquery";

export default function UserReligionInfoComponent({userBasicInfo,issubmit,setIsSubmit}){
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const { handleSubmit,getValues ,setValue,clearErrors, control, formState: { errors } } = useForm({mode : "onBlur",defaultValue:{
  	ud_religion:"",ud_caste:"",ud_subcaste:"",ud_pattam_other_details:""
  }});
 	const [getReligionData] = useReligion("religion/0/0/0");
  const [getCaste,setgetCaste] = useState([]);
  const [getSubCaste,setgetSubCaste] = useState([]);

  const handleReligionChange= useCallback((val) => {
		if(val!==''){
			if(val!==getValues('ud_religion')){
				setValue('ud_caste','');
				
			}
			setValue('ud_religion',val);
			Axios.get(Constants.API_URL+"religion/"+val+"/0/0").then((response)=>{
				if(response.data.data){
					setgetCaste(response.data.data);
				}
			});
		}
	},[getValues,setValue])

	const handleCasteChange= useCallback((val) => {
		if(val!==''){
			clearErrors("ud_caste");
			setValue('ud_caste',val);
			Axios.get(Constants.API_URL+"religion/"+getValues('ud_religion')+"/"+val+"/0").then((response)=>{
				if(response.data.data){
					setgetSubCaste(response.data.data);
				}
			});
		}
	},[clearErrors,getValues,setValue])

	// function handleSubCasteChange(e){
	// 	setValue('ud_subcaste',e.value);
	// 	clearErrors("ud_subcaste");
	// }

	const onRelSubmit = (data) => {
  	Axios.post(Constants.API_URL+"postmyprofile",{
  		api_token : userToken,ud_religion : data.ud_religion,ud_caste : data.ud_caste,
  		ud_subcaste : data.ud_subcaste, ud_pattam_other_details : data.ud_pattam_other_details
  	}).then((response)=>{
      setIsSubmit('');
      setIsSubmit(response.data.error+'/'+response.data.message);
    	$(".closeRelInfoModal").click();
    }).catch((err) => {
    	console.log(err);
    })
  }

	useEffect(() => {
		if(userBasicInfo!==null){
			setValue('ud_religion',userBasicInfo.ud_religion);
  		setValue('ud_caste',userBasicInfo.ud_caste);
  		setValue('ud_subcaste',userBasicInfo.ud_subcaste);
		  setValue('ud_pattam_other_details',userBasicInfo.ud_pattam_other_details);
  		handleReligionChange(userBasicInfo.ud_religion);

		}
	},[userBasicInfo,setValue,handleReligionChange])

	return (
			<div className="modal fade gtEditForm" id="religionInfoModal" tabIndex="-1" role="dialog" aria-labelledby="religionInfoModal" aria-hidden="true" data-backdrop="static">
			  <div className="modal-dialog" role="document">
			    <div className="modal-content">
			      <div className="modal-header text-center">
			        <div className="col-12">
			          <h5 className="modal-title" id="exampleModalLabel">Religion Information
			          <button type="button" className="closeRelInfoModal close" data-dismiss="modal" aria-label="Close">
			              <span aria-hidden="true">×</span>
			          </button>
			          </h5>
			        </div>
			      </div>
			      <div className="modal-body">
			        <div className="row">
			          <form className="form-horizontal" onSubmit={handleSubmit(onRelSubmit)}>          
			            <div className="container">
			              <div className="row">
			                <div className="col-xl-12">
			                  <div className="form-group">
			                    <label htmlFor="Userdetails_ud_religion" className="required">Religion <span className="required">*</span>
			                    </label>              
			                    {getReligionData && (
	                        	<Controller
					                   name="ud_religion"
					                   control={control}
					                   render={({ field: { onChange, value, name, ref } }) => (
					                     <Select
					                      inputRef={ref}
					                      placeholder ={t('select_any_one')}
					                      className="mb-4"
					                      value={getReligionData.find((c) => c.value === value) || ''}
					                      options={getReligionData}
					                      defaultValue={Constants.prompt}
					                      onChange={(e) => handleReligionChange(e.value)} 
					                     />
					                   )} 
				                 	/>)}                              
			                  </div> 
			                </div>
			                <div className="col-xl-12">
			                  <div className="form-group">
			                    <label htmlFor="Userdetails_ud_caste" className="required">Caste <span className="required">*</span></label>              
				                   {getCaste && (
	                        	<Controller
					                   name="ud_caste"
					                   control={control}
					                   rules = {{required:true}}
					                   render={({ field: { onChange, value, name, ref } }) => (
					                     <Select
					                      inputRef={ref}
					                      placeholder ={t('select_any_one')}
					                      className="mb-4"
					                      value={getCaste.find((c) => c.value === value) || ''}
					                      options={getCaste}
					                      defaultValue={Constants.prompt}
					                      onChange={(e) => handleCasteChange(e.value)} 
					                     />
					                   )} 
					                 />)}          
					                 {errors.ud_caste?.type==="required" && <p className="error">{t('caste_cannot_be_blank')}</p>}                    
			                  </div>
			                </div>
			                <div className="col-xl-12">
			                  <div className="form-group">
			                    <label htmlFor="Userdetails_ud_pattam_other_details" className="required">Sub Caste </label> 
								<Controller
														name="ud_pattam_other_details"
														control={control}
														render={({ field }) => (
															<input  {...field}
																className="form-control"
															/>
														)}
														rules={{
															required: true,
															maxLength: 32,
															pattern: /^[a-zA-Z]+$/
														}}

														/>
			                  </div>
			                  <div className="col-xl-12">
			                    <div className="form-group text-center">
			                      <input className="btn btnRegFsubmit" name="religion" type="submit" value="Update" />           
			                    </div>
			                  </div>
			                </div>
			              </div>
			            </div>
			          </form>        
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
		)
	}
