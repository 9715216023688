import React, { useEffect,useState } from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import Constants from "../services/Constants";

export default function PrintProfileComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user,setUser] = useState({});
  let setting = JSON.parse(localStorage.getItem('setting')); 

  useEffect(() => {
    if(location.state!=null){
      if(location.state.data!==null){
         setUser(location.state.data);
         console.log(location.state.data);
          if(location.state.title) navigate(location.pathname, { replace: true });
      }
    }
  },[location,navigate]);

  const tdStyle = {"border": "3px solid #000","fontSize":"21px", "fontWeight":"bold"};
  const tdContent = {"color":"red","fontSize":"20px"};
  const tdCenter = {"color":"red","fontSize":"21px"};

  return(
    <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{'fontSize':'12px!important','borderCollapse':'collapse!important'}}>
      <tr>
        <td align="center">
          <table border="0" cellPadding="0" cellSpacing="0" width="940" style={{"border": "1px solid #000","borderRadius":"5px", "padding":"10px"}} className="lang-en">
            <tr>
              <td className="lang-en">
                <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{"height":"263px"}}>
                  <tr>
                    <td valign="top" align="center">
                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{"border-top": "3px solid #000","border-left": "3px solid #000","border-right": "3px solid #000"}}>
                        <tr>
                          <td align="center">
                            <img src={require('../assets/images/horoscope_logo.png')} border="0" alt="LogoImage" /> 
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{"borderTop":"3px solid #000","borderBottom":"3px solid #000"}}>
                              <tbody>
                                <tr>
                                  <td align="center" colSpan="3" style={{"paddingTop":"3px","paddingBottom":"3px"}}>
                                    <table cellPadding="0" cellSpacing="0" border="0" width="100%">
                                      <tr>
                                        <td align="center" width="50%">
                                          <span className="lang-en">
                                            <b>
                                              <span style={{"textDecoration":"underline"}}>Website</span> 
                                              <span> : </span>
                                              <span style={{"fontSize":"15px"}}>{Constants.APP_URL}</span>
                                            </b>
                                          </span>
                                        </td>
                                        <td align="center">
                                          <span className="lang-en">
                                            <b>
                                              <span style={{"textDecoration":"underline"}}>Email</span>
                                              <span> : </span>
                                              <span style={{"fontSize":"15px"}}>{setting.s_contact_email} </span>
                                            </b>
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <table  cellPadding="0" cellSpacing="0" border="0" width="100%" style={{"border": "0px solid #000","padding":"7px","height":"40px"}}>
                              <tr>
                                <td className='lang-en' width="40%" align="center">
                                  <b>Name : {user.fname  +  user.lname} </b>
                                </td>
                                <td width="30%" align="center">
                                  <b>Gender : {user.gender}</b>
                                </td>
                                <td width="30%" align="center">
                                  <b>MEMBER ID : <span className="lang-en"><b>{user.matri_id}</b> </span></b>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{"border-top": "2px solid #000","border-bottom": "2px solid #000", "padding":"5px"}}>
                              <tr>
                                <td align="center">
                                  <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{"borderBottom":"0px solid #000","height":"460px"}}>
                                    <tr>
                                      <td width="50%" valign="top">
                                        <table cellPadding="4" cellSpacing="0" border="0" width="100%" style={{"fontSize":"16px"}}>
                                          <tr>
                                            <td width="30%"><b> Mother Tongue </b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.toungue} </b><span></span></td>
                                          </tr>
                                          <tr>
                                            <td><b>Marital Status</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.marital_status}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Profile Created by</b></td>
                                            <td><b>:</b></td>
                                            <td><b> {user.profile_created_by}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Date of Birth </b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.dob}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Physical Status</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.physical_status}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Age </b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.age} Years</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Blood Group</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_blood_group}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Height</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.height}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Weight</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.weight}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Father Name</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.f_name}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Mother Name</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.m_name}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Father's Occupation</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.father_occupation}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Mother's Occupation</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.mother_occupation}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Brothers</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_bro}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Married Brothers</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_married_bro}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Sisters</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_sis}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Married Sisters</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_married_sis}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Diet</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.eating_habits}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Smoke</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.smoking_habits}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Drink</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.drinking_habits}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>BodyType</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.body_type}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Complexion</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.complexion_status}</b></td>
                                          </tr>
                                        </table>
                                      </td>
                                      <td width="50%" valign="top" style={{"borderLeft": "3px solid #000", "paddingLeft":"10px"}}>
                                        <table cellPadding="4" cellSpacing="0" border="0" width="100%">
                                          <tr>
                                            <td width="45%"><b>Religion</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.religion}</b></td>
                                          </tr>
                                          <tr>
                                            <td ><b>Caste</b></td>
                                            <td><b>:</b></td> 
                                            <td><b>{user.caste}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Pattam</b></td>
                                            <td><b>:</b></td>
                                             <td><b>{user.subcaste}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Address</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_address}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Country</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.country}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>State</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.state}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>City</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.city}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Education Details</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.education}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Occupation</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.occupation}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Family Value</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.family}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Employed in</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.employee}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Annual Income</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.income}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Dhisa Iruppu</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_dhisa+'-'+user.ud_dhisa_year+'Y-'+user.ud_dhisa_month+'M-'+user.ud_dhisa_date+'D'}
                                           </b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Star</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_star}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Rasi</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_rasi}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Laknum</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_laknum}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Birth Time</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_birthtime}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Birth Place</b></td>
                                            <td><b>:</b>&nbsp;</td>
                                            <td><b>{user.ud_birthplace}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Manglik / Dhosam</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_manglik}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Kuladeivam</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_birthgod}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Gothram</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_gothram}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Expectation</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.about}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Mobile no</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.mobile_no}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Alternative Mobile No</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.phone}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Email</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.email}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Properties</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_properties}</b></td>
                                          </tr>
                                          <tr>
                                            <td><b>Aadhar No</b></td>
                                            <td><b>:</b></td>
                                            <td><b>{user.ud_adhar}</b></td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <table className="lang-ta mt-4" cellPadding="0" cellSpacing="0" border="0" width="100%" style={{"height":"400px"}}>
                          <tr>
                            <td width="40%" align="center">
                              <table border="0" cellPadding="5" cellSpacing="0" width="304" height="305" style={{"border": "3px solid #000"}}>
                                <tr>
                                  <td className="lang-ta" width="60" height="60"  id="rasi_box12" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r12}</b></td>
                                  <td className="lang-ta"  width="60" height="60" id="rasi_box1" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r1}</b></td>
                                  <td className="lang-ta" width="61" height="60" id="rasi_box2" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r2} </b></td>
                                  <td className="lang-ta" width="61" height="60" id="rasi_box3" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r3}</b></td>
                                </tr>
                                <tr>
                                  <td className="lang-ta" width="60" height="61" id="rasi_box11" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r11}</b></td>
                                  <td colSpan="2" rowSpan="2" height="118" align="center"  style={{"border": "3px solid #000"}}><p align="center"> <b><span className='lang-ta' style={tdCenter}>uhrp<br/></span></b></p></td>
                                  <td className="lang-ta" width="61" height="61" id="rasi_box4" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r4}</b></td>
                                </tr>
                                <tr>
                                  <td className="lang-ta" width="60" height="61" id="rasi_box10" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r10}</b></td>
                                  <td className="lang-ta" width="61" height="61" id="rasi_box5" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r5}</b></td>
                                </tr>
                                <tr>
                                  <td className="lang-ta" width="60" height="61" id="rasi_box9" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r9}</b></td>
                                  <td className="lang-ta" width="60" height="61" id="rasi_box8" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r8}</b></td>
                                  <td className="lang-ta" width="61" height="61" id="rasi_box7" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r7}</b></td>
                                  <td className="lang-ta" width="61" height="61" id="rasi_box6" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_r6}</b></td>
                                </tr>
                              </table>
                            </td>
                            <td width="20%">
                              <img width="200px" className="thumbnail img-responsive" src={user.image!=="" ? Constants.IMAGE_URL+"pictures/"+user.image : (user.genderval===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png'))} alt="ProfileImage"/>
                            </td>
                            <td width="40%" align="center">
                              <table  className="lang-ta"  border="0" cellPadding="5" cellSpacing="0" width="304" height="305" style={{"border": "3px solid #000"}}>
                                <tr>
                                  <td width="60" height="60" align="center" id="rasi_box12"  style={tdStyle}><b style={tdContent}>{user.ud_p_a12}</b></td>
                                  <td width="60" height="60" align="center" id="rasi_box1" style={tdStyle}><b style={tdContent}>{user.ud_p_a1}</b></td>
                                  <td width="61" height="60" align="center" id="rasi_box2" style={tdStyle}><b style={tdContent}>{user.ud_p_a2}</b></td>
                                  <td width="61" height="60" align="center" id="rasi_box3" style={tdStyle}><b style={tdContent}>{user.ud_p_a3}</b></td>
                                </tr>
                                <tr>
                                  <td width="60" height="61" align="center" id="rasi_box11" style={tdStyle}><b style={tdContent}>{user.ud_p_a11}</b></td>
                                  <td colSpan="2" rowSpan="2" height="118" align="center"  style={{"border":"3px solid #000"}}><p align="center"> <b><span className='lang-ta' style={tdCenter}>mk;rk; </span><br/></b> </p></td>
                                  <td width="61" height="61" id="rasi_box4" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_a4}</b></td>
                                </tr>
                                <tr>
                                  <td width="60" height="61" id="rasi_box10" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_a10}</b></td>
                                  <td width="61" height="61" id="rasi_box5" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_a5}</b></td>
                                </tr>
                                <tr>
                                  <td width="60" height="61" id="rasi_box9" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_a9}</b></td>
                                  <td width="60" height="61" id="rasi_box8" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_a8}</b></td>
                                  <td width="61" height="61" id="rasi_box7" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_a7}</b></td>
                                  <td width="61" height="61" id="rasi_box6" align="center" style={tdStyle}><b style={tdContent}>{user.ud_p_a6}</b></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td align="center">
                <div style={{"border": "3px solid #000","padding":"5px","borderRadius":"5px","fontSize":"19px","lineHeight":"1.2","textAlign":"center"}}>
                  <b>Address:</b>{setting.s_company_address} . 
                  <b>Contact us:</b>{setting.s_contact_no}
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  )
}