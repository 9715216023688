import { useState,useEffect,useCallback } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import { useForm } from 'react-hook-form';
import Constants from "../services/Constants"; 
import MobileModalComponent from '../views/userMobileModal';
import BasicInfoModalComponent from '../views/userBasicInfoModal';
import UserReligionInfoComponent from '../views/userRelModal';
import UserLocationModalComponent from '../views/userLocModal';
import UserProfInfoComponent from '../views/userProfInfoModal';
import UserJathagamInfoModal from '../views/userJathagamInfoModal';
import UserAboutComponent from '../views/userAboutModal';
import UserPartnerBasicInfoComponent from '../views/userPartnerBasicModal';
import UserPartnerProInfoComponent from '../views/userPartnerProModal';
import UserPartnerLocComponent from '../views/userPartnerLocModal';
import UserPartnerPrefComponent from '../views/userPartnerPrefModal';
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function ProfileComponent() {
  let userID = localStorage.getItem("userId");
  let userToken = JSON.parse(localStorage.getItem('userToken')); 
   let setting = JSON.parse(localStorage.getItem('setting')); 
  const { t } = useTranslation();
  let title = '';
  const [myprofile,setMyprofile]= useState({ud_fname : "",ud_mname : "",ud_familyvalue : "",ud_fname : "",ud_mname : "", 
  	ud_diet: "",ud_dob:"",ud_drink:"",ud_education:"",ud_email: "",ud_employee:"",ud_firstname: "",ud_gender: "",ud_height: "",
		ud_income: "",ud_lastname: "",ud_marital: "",ud_matri_id: "", ud_mobile: "",ud_occupation: "",ud_physical: "",ud_pic: "", 
		ud_religion: "",ud_smoke: "",ud_state: "", ud_subcaste: "",ud_toungue: "",ud_forigin: "",ud_weight: "",ud_horoscope_pic:false});
  const [updateuser,setUpdateUser]= useState({ud_horoscope_pic: ""});
  const [dateDOB,setdateDOB]= useState(new Date()); 
  const [issubmit,setIsSubmit]= useState('');
  const [loading,setLoading]= useState(0);
  const [partnerProfile,setPartnerProfile]= useState({});
  const [picture, setPicture] = useState({file:{},name:""});
  const { register, handleSubmit } = useForm();
  
  const [inputErrors,setInputErrors]= useState(0);
  const [update,setUpdate] = useState(0);
  const [dropdownData,setDropdownData] = useState({getReligion:[],getCountry:[],getOccupation:[],getMotherToungue:[],getEducation:[],getCountryCode:[]});
  const [rasiSelect] = useState({'rasi_1':[],'rasi_2':[],'rasi_3':[],'rasi_4':[],'rasi_5':[],'rasi_6':[],'rasi_7':[],'rasi_8':[],'rasi_9':[],'rasi_10':[],'rasi_11':[],'rasi_12':[]});
  const [amsamSelect] = useState({'amsam_1':[],'amsam_2':[],'amsam_3':[],'amsam_4':[],'amsam_5':[],'amsam_6':[],'amsam_7':[],'amsam_8':[],'amsam_9':[],'amsam_10':[],'amsam_11':[],'amsam_12':[]});


  useEffect(() => {
  	function editProfile(){
	  	Axios.post(Constants.API_URL+"editprofile",{ api_token : userToken
	  	}).then((response)=>{
	  		setLoading(1);
	  		setUpdateUser(response.data.data.user);
	  		setPartnerProfile(response.data.data.partner);
	  	})
	  	Axios.post(Constants.API_URL+"myprofile",{ api_token : userToken
	    }).then((response)=>{

        console.log(response.data.data);

	      setMyprofile(response.data.data); 

	    });
  	};
  	editProfile();
  },[issubmit,update,userToken]);

		 const getdropdownData = useCallback(() => {
		 		Axios.get(Constants.API_URL+"getdropdowndata").then((response)=>{
					if(response.data.data){
						setDropdownData(prevData =>  { return {...prevData,
							getReligion:response.data.data.getReligion,
							getCountry:response.data.data.getCountry,
							getOccupation:response.data.data.getOccupation,
							getMotherToungue:response.data.data.getMotherToungue,
							getEducation:response.data.data.getEducation,
							getCountryCode:response.data.data.getCountryCode}});
					}
				}).catch(errors => {
						console.log(errors);
				});
		 },[])	

  useEffect(() => {
		 getdropdownData();
		},[getdropdownData])


  const onSubmit = (data) => {
    if(inputErrors===0){
	    Axios.post(Constants.API_URL+"horoscoprofileupload",{
	    	api_token :userToken,user_id : userID,horoscope_pic : picture.file
	    },{ headers: { "content-type": "multipart/form-data" },
	    }).then((response)=>{ 
	        setPicture({...picture,name:""});
	        setIsSubmit(response.data.error+'/'+response.data.message);
	    }).catch((err) => {
	        console.log(err);
	    })
    }
  }; 

  const getHoroscopeDownload = () => {
  	Axios({
		  url: Constants.API_URL+"horoscoprofiledownload/"+userToken+"/"+userID,
		  method: 'GET',responseType: 'blob', 
		}).then((response) => {
			if(typeof(response)==="object"){
				if(response.data.size===1){
					toast.error(t('file_not_exist'));
				}else{
			    const url = window.URL.createObjectURL(new Blob([response.data]));
				  const ext = response.data.type.split('/');
			    const link = document.createElement('a');
			    link.href = url;
			    link.setAttribute('download',myprofile.ud_matri_id+'.'+ext[1]); 
			    document.body.appendChild(link);
			    link.click();
				}
			}
		});
  }

  

  
  const showRasi = (value) =>{
    let split = '';
    if(rasiSelect[value].length>=0){
      split = rasiSelect[value].join(' ');
      return split;
    }else{
      return 0;
    }
    
  }

  const showAmsam = (value) =>{
    let split = '';
    if(amsamSelect[value].length>=0){
      split = amsamSelect[value].join(' ');
      return split;
    }else{
      return 0;
    }
    
  }

  const onChangePicture = (e) => {
    if (e.target.files[0]){
      setPicture({...picture,file:e.target.files[0],name:e.target.files[0].name});
      var type = e.target.files[0].name;
      if(type){
        var ext = type.split('.');
        if(ext[1]==='jpg' || ext[1]==='jpeg' || ext[1]==='png' || ext[1]==='pdf'){
          setInputErrors(0);
        }else setInputErrors(1);
      } 
    }
  }

	if(issubmit!==''){
		title = issubmit.split('/');
		if(title[0]==="true") toast.error(title[1]);
		else toast.success(title[1]);
		setIsSubmit('');
		setUpdate(update + 1);
		title = '';
	}

  const setDateDateofBirth = () => { 
  	if(updateuser!==null){
  		setdateDOB(new Date(updateuser.ud_dob)); 
  	}
  }

  return (
    	<div>
    		{loading===0 && 
        <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
          <Skeleton />
        </SkeletonTheme>}
				<ToastContainer />
    	  <div className="container mt-5">
    	    <h3 className="gtPageTitle text-left mt-4 mb-2 ml-2">{myprofile.ud_firstname  +" "+  myprofile.ud_lastname}  
    	    <small>({myprofile.ud_matri_id})</small></h3>
    	    <div className="row">
				    <div className="col-xl-12 col-12">
				      <div className="gtProBoxT card">
				        <div className="gtProBoxTBody gtXSP15">
				          <div className="row">
				            <div className="col-xl-3 col-sm-4 photo">
						          {myprofile.ud_gender===1 && <img alt={myprofile.ud_matri_id} src={myprofile.ud_pic!=="" ? Constants.IMAGE_URL+"pictures/"+myprofile.ud_pic : require('../assets/images/no_image_groom.png')} className="img-fluid img-shadow" />}
		  			          {myprofile.ud_gender===2 && <img alt={myprofile.ud_matri_id} src={myprofile.ud_pic!=="" ? Constants.IMAGE_URL+"pictures/"+myprofile.ud_pic : require('../assets/images/no_image_bride.png')} className="img-fluid img-shadow" />}
				            </div>
				            <div className="col-xl-9 col-sm-8 pt-4">
				              <div className="row">
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('name')}</div>
				                    <div className="col-7 gtProTDet">{myprofile.ud_firstname  +" "+  myprofile.ud_lastname}</div>
				                  </div>
				                </div>
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('mobile_no')}</div>
				                    <div className="col-7 gtProTDet">{myprofile.ud_mobile}</div>
				                  </div>
				                </div>
				              </div>
				              <div className="row">
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('email')}</div>
				                    <div className="col-7 gtProTDet">{myprofile.ud_email}</div>
				                  </div>
				                </div>
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('gender')}</div>
				                    <div className="col-7 gtProTDet">{myprofile.ud_gender==1 ? t('male') : t('female')}</div>
				                  </div>
				                </div>
				              </div>
				              <div className="row">
                        <div className="col-xl-6 mb-3">
                        <div className="row">
                          <div className="col-5 gtProTTag">{t('f_name')}</div>
                          <div className="col-7 gtProTDet">{myprofile.ud_fname}</div>
                        </div>
                      </div>
                      <div className="col-xl-6 mb-3">
                        <div className="row">
                          <div className="col-5 gtProTTag">{t('m_name')}</div>
                          <div className="col-7 gtProTDet">{myprofile.ud_mname}</div>
                        </div>
                      </div>
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('date_of_birth')}</div>
				                    <div className="col-7 gtProTDet">{myprofile.ud_dob}</div>
				                  </div>
				                </div>
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('martial_status')}</div>
				                    <div className="col-7 gtProTDet">{myprofile.ud_marital}</div>
				                  </div>
				                </div>
				              </div>
				              <div className="row">
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('profile_created_for')} </div>
				                    <div className="col-7 gtProTDet">{myprofile.ud_profile}</div>
				                  </div>
				                </div>
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('mother_toungue')}</div>
				                    <div className="col-7 gtProTDet">{myprofile.ud_toungue}</div>
				                  </div>
				                </div>
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				      <h3 className="gtPageTitle text-left mt-4 ml-2">{t('personal_information')}</h3>
	      			<p className="gtPageSubTitle text-left mb-4  ml-2">{t('you_will_find')}</p>
		  			  <div className="gtProBox card mb-3">
				        <div className="gtProBoxHead">
				          <div className="row">
				            <div className="col-5"><h4>{t('basic_information')}</h4></div>
				            <div className="col-7 text-right">
				              <a href="#mobileModal" className="btn btn-green" data-toggle="modal" ><i className="fas fa-pen mr-2 "></i>{t('edit_mobile_no')}</a>&nbsp;
				              <a onClick={setDateDateofBirth} href="#basicInfoModal" className="btn btn-green btn-top-ali" data-toggle="modal" ><i  className="fas fa-pen mr-2"></i>{t('edit')}</a>
				            </div>
				          </div>
				        </div>
				        <div className="gtProBoxBody">
				          <div className="row">
                  <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('f_name')}</div>
				                <div className="col-7 gtProTxtDet">{myprofile.ud_fname}</div>
				              </div>
				            </div>
                    
                    <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">Fathers Origin Village</div>
				                <div className="col-7 gtProTxtDet">{myprofile.ud_forigin}</div>
				              </div>
				            </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">Fathers Phone No</div>
				                <div className="col-7 gtProTxtDet">{myprofile.ud_fphone}</div>
				              </div>
				            </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('m_name')}</div>
				                <div className="col-7 gtProTxtDet">{myprofile.ud_mname}</div>
				              </div>
				            </div>
                   
                    <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">Mothers Origin Village</div>
				                <div className="col-7 gtProTxtDet">{myprofile.ud_morigin}</div>
				              </div>
				            </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">Mothers Phone No</div>
				                <div className="col-7 gtProTxtDet">{myprofile.ud_mphone}</div>
				              </div>
				            </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">Aadhar No (optional)</div>
				                <div className="col-7 gtProTxtDet">{myprofile.ud_adhar}</div>
				              </div>
				            </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('age')}</div>
				                <div className="col-7 gtProTxtDet">{myprofile.ud_age}</div>
				              </div>
				            </div>
                    
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('physical_status')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_physical}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">Diet</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_diet}</div>
                      </div>
                    </div>
				          </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('height')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_height}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('weight')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_weight}</div>
                      </div>
                    </div>
                  </div>
				          {/* <div className="row">
                  <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('no_of_children')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_child}</div>
                      </div>
                    </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('children_living_status')}</div>
				                <div className="col-7 gtProTxtDet">{myprofile.ud_childstatus}</div>
				              </div>
				            </div>
                    
				          </div> */}
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('blood_group')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_blood_group}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('properties')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_properties}</div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('brothers')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_bro}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('married_brothers')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_married_bro}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('married_brother_details')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_married_brother_details}</div>
                      </div>
                    </div>
                  {/* </div>
                  <div className="row"> */}
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('sisters')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_sis}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('married_sis')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_married_sis}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('married_sis_details')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_married_sister_details}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('complexion')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_complexion}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('body_type')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_bodytype}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('eating_habits')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_diet}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('drinking_habits')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_drink}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('smoking_habits')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_smoke}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('address')}</div>
                        <div className="col-7 gtProTxtDet">{myprofile.ud_address}</div>
                      </div>
                    </div>
                  </div>
				        </div>
				        <BasicInfoModalComponent userBasicInfo={updateuser} dateDOB={dateDOB} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
						    <MobileModalComponent CountryCode={dropdownData.getCountryCode} userMobiledata={updateuser} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
						    </div>
								<div className="mt-4"></div>
						      <div className="gtProBox card mb-3">
						        <div className="gtProBoxHead">
						          <div className="row">
						            <div className="col-7"><h4>{t('religion_information')}</h4></div>
						            <div className="col-5 text-right"><a href="#religionInfoModal" className="btn btn-green" data-toggle="modal"><i className="fas fa-pen mr-2"></i>{t('edit')}</a></div>
						          </div>
						        </div>
						        <div className="gtProBoxBody">
						          <div className="row">
						            <div className="col-xl-6 col-lg-6 mb-3">
						              <div className="row">
						                <div className="col-5 gtProTxt">{t('religion')}</div>
						                <div className="col-7 gtProTxtDet">{myprofile.ud_religion} </div>
						              </div>
						            </div>
						            <div className="col-xl-6 col-lg-6 mb-3">
						              <div className="row">
						                <div className="col-5 gtProTxt">{t('caste')}</div>
						                <div className="col-7 gtProTxtDet">{myprofile.ud_caste}</div>
						              </div>
						            </div>
						          </div>
						          <div className="row">
						            <div className="col-xl-6 col-lg-6 mb-3">
						              <div className="row">
						                <div className="col-5 gtProTxt">{t('sub_caste')}</div>
						                <div className="col-7 gtProTxtDet">{myprofile.ud_pattam_other_details}</div>
						              </div>
						            </div>
						          </div>
						        </div>
							    </div>
				  				<UserReligionInfoComponent userBasicInfo={updateuser} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
					        <div className="mt-4"></div>
					        <div className="gtProBox card mb-3">
					          <div className="gtProBoxHead">
					            <div className="row">
					              <div className="col-7"><h4>{t('location_information')}</h4></div>
					              <div className="col-5 text-right"><a href="#locationInfoModal" className="btn btn-green" data-toggle="modal"><i className="fas fa-pen mr-2"></i>{t('edit')}</a></div>
					            </div>
					          </div>
					          <div className="gtProBoxBody">
					            <div className="row">
					              <div className="col-xl-6 col-lg-6 mb-3">
					                <div className="row">
					                  <div className="col-5 gtProTxt">{t('country')}</div>
					                  <div className="col-7 gtProTxtDet">{myprofile.ud_country}</div>
					                </div>
					              </div>
					              <div className="col-xl-6 col-lg-6 mb-3">
					                <div className="row">
					                  <div className="col-5 gtProTxt">{t('state')}</div>
					                  <div className="col-7 gtProTxtDet">{myprofile.ud_state}</div>
					                </div>
					              </div>
					            </div>
					            <div className="row">
					              <div className="col-xl-6 col-lg-6 mb-3">
					                <div className="row">
					                  <div className="col-5 gtProTxt">{t('city')}</div>
					                  <div className="col-7 gtProTxtDet">{myprofile.ud_city_other}</div>
					                </div>
					              </div>
					            </div>
					          </div>
					          <UserLocationModalComponent userBasicInfo={updateuser} userCountryData={dropdownData.getCountry} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
					        </div>
					        <div className="mt-4"></div>
				          <div className="gtProBox card mb-3">
					          <div className="gtProBoxHead">
					            <div className="row">
					              <div className="col-7"><h4>{t('professional_information')}</h4></div>
					              <div className="col-5 text-right"><a href="#professionalInfoModal" className="btn btn-green" data-toggle="modal"><i className="fas fa-pen mr-2"></i>{t('edit')}</a></div>
					            </div>
					          </div>
					          <div className="gtProBoxBody">
					            <div className="row">
					              <div className="col-xl-6 col-lg-6 mb-3">
					                <div className="row">
					                  <div className="col-5 gtProTxt">{t('professional')}</div>
					                  <div className="col-7 gtProTxtDet">{myprofile.ud_education}</div>
					                </div>
					              </div>
					              <div className="col-xl-6 col-lg-6 mb-3">
					                <div className="row">
					                  <div className="col-5 gtProTxt">{t('education_details')}</div>
					                  <div className="col-7 gtProTxtDet">{myprofile.ud_additional}</div>
					                </div>
					              </div>
					            </div>
					            <div className="row">
					              <div className="col-xl-6 col-lg-6 mb-3">
					                <div className="row">
					                  <div className="col-5 gtProTxt">{t('occupation')}</div>
					                  <div className="col-7 gtProTxtDet">{myprofile.ud_occupation}</div>
					                </div>
					              </div>
					              <div className="col-xl-6 col-lg-6 mb-3">
					                <div className="row">
					                  <div className="col-5 gtProTxt">{t('employed_in')}</div>
					                  <div className="col-7 gtProTxtDet">{myprofile.ud_employee}</div>
					                </div>
					              </div>
					            </div>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('father_occupation')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_father_occupation}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('mother_occupation')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_mother_occupation}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('annual_income')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_income}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('family_value')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_familyvalue}</div>
                          </div>
                        </div>
                      </div>
					          </div>
					          <UserProfInfoComponent userBasicInfo={updateuser} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
					        </div>
			      			<div className="mb-4"></div>
			        		<div className="gtProBox card mb-3">
			        			{(setting!==null && setting.s_horoscope_option===1 && updateuser.ud_horoscope_pic!=="" && myprofile.ud_horoscope_pic===true)  ?
			        			<div className="gtProBoxHead">
						          <div className="row">
						            <div className="col-6"><h4>{t('horoscope_kundli_information')}</h4></div>
						            <div className="col-6 text-right">
						            	<button onClick={getHoroscopeDownload} className="btn btn-green" ><i className='fas fa-download'></i>  {t('download')}</button>
                        </div>
						          </div>
						        </div> : 
                  <div className="gtProBoxHead">
                      <div className="row">
                        <div className="col-7"><h4>{t('zodiac_information')}</h4></div>
                        <div className="col-5 text-right"><a href="#userJathagamInfoModal" className="btn btn-green" data-toggle="modal"><i className="fas fa-pen mr-2"></i>{t('edit')}</a></div>
                      </div>
                    </div>}
                        <UserJathagamInfoModal userBasicInfo={updateuser} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
                       
                        <>
                      <div>
                      <div className="gtProBoxBody">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('rasi_moon_sign')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_rasi}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('star_nakshatra')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_star}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('lagnum')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_laknum}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('birth_time')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_birthtime}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('birth_place')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_birthplace}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('manglik_dhosam')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_manglik}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">Dhosa</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_dosh}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('thisai_iruppu')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_dhisa+'-'+myprofile.ud_dhisa_year+'Y-'+myprofile.ud_dhisa_month+'M-'+myprofile.ud_dhisa_date+'D'}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('ud_birthgod')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_birthgod}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('ud_gothram')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_gothram}</div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-3">
                          <div className="row">
                            <div className="col-5 gtProTxt">{t('ud_poorvigam')}</div>
                            <div className="col-7 gtProTxtDet">{myprofile.ud_poorvigam}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  <div className="form-group row">
                    <div className='col-xl-6 mt-4'>
                              <table width="288" height="260" border="0" align="center" cellPadding="0" cellSpacing="0">
                              <tbody>
                                <tr className="table-design lang-ta">
                                  <td width="70" height="70" className="table-design lang-ta " id="rasi_box12" style={{'background':`#fff ${Constants.rasi12_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_12"style={{'color':'red'}}>
                                      {updateuser.ud_p_r12}
                                        </span> 
                                      <input  id="rasi_txt_12" name="ud_p_r12" type="hidden" value={showRasi('rasi_12')} {...register("ud_p_r12")}/>
                                  </td>
                                  <td width="70" height="70" className="table-design lang-ta " id="rasi_box1" style={{'background':`#fff ${Constants.rasi1_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_1" style={{'color':'red'}}>{updateuser.ud_p_r1}</span> 
                                      <input  id="rasi_txt_1" name="ud_p_r1" type="hidden" value={showRasi('rasi_1')} {...register("ud_p_r1")} />
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box2" style={{'background':`#fff ${Constants.rasi2_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_2" style={{'color':'red'}}>{updateuser.ud_p_r2}</span> 
                                    <input  id="rasi_txt_2" name="ud_p_r2" type="hidden" value={showRasi('rasi_2')} {...register("ud_p_r2")} />
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box3" style={{'background':`#fff ${Constants.rasi3_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_3" style={{'color':'red'}}>{updateuser.ud_p_r3}</span> 
                                    <input  id="rasi_txt_3" name="ud_p_r3" type="hidden" value={showRasi('rasi_3')} {...register("ud_p_r3")} />
                                </td>
                                </tr>
                                <tr>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box11" style={{'background':`#fff ${Constants.rasi11_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_11" style={{'color':'red'}}>{updateuser.ud_p_r11}</span> 
                                    <input  id="rasi_txt_11" name="ud_p_r11" type="hidden" value={showRasi('rasi_11')} {...register("ud_p_r11")} />
                                  </td>
                                  <td colSpan="2" rowSpan="2" align="center" valign="middle" style={{'verticalAlign':'middle','textAlign':'center','backgroundColor':'#FFF' ,'border':'#CCC solid 1px'}}>
                                    <tr width="142" border="0" align="center">
                                        <td width="155" height="115" bgcolor="#FFFF00"><div align="center"><span className="lang-ta">uhrp</span></div></td>
                                    </tr>
                                  </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box4" style={{'background':`#fff ${Constants.rasi4_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_4" style={{'color':'red'}}>{updateuser.ud_p_r4}</span>
                                    <input  id="rasi_txt_4" name="ud_p_r4" type="hidden" value={showRasi('rasi_4')} {...register("ud_p_r4")} />
                                  </td>
                                </tr>
                                <tr>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box10" style={{'background':`#fff ${Constants.rasi10_Url.one} no-repeat center`,'border':'1px solid #000'}}> 
                                      <span className="rasi" id="rasi_10" style={{'color':'red'}}>{updateuser.ud_p_r10}</span> 
                                      <input  id="rasi_txt_10" name="ud_p_r10" type="hidden" value={showRasi('rasi_10')} {...register("ud_p_r10")} />
                                      </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box5" style={{'background':`#fff ${Constants.rasi5_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_5" style={{'color':'red'}}>{updateuser.ud_p_r5}</span> 
                                      <input  id="rasi_txt_5" name="ud_p_r5" type="hidden" value={showRasi('rasi_5')} {...register("ud_p_r5")} />
                                    </td>
                                </tr>
                                <tr>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box9" style={{'background':`#fff ${Constants.rasi9_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_9" style={{'color':'red'}}>{updateuser.ud_p_r9}</span> 
                                      <input  id="rasi_txt_9" name="ud_p_r9" type="hidden" value={showRasi('rasi_9')} {...register("ud_p_r9")}/>
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box8" style={{'background':`#fff ${Constants.rasi8_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_8" style={{'color':'red'}}>{updateuser.ud_p_r8}</span>
                                      <input  id="rasi_txt_8" name="ud_p_r8" type="hidden" value={showRasi('rasi_8')} {...register("ud_p_r8")}/>
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box7" style={{'background':`#fff ${Constants.rasi7_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_7" style={{'color':'red'}}>{updateuser.ud_p_r7}</span>
                                      <input  id="rasi_txt_7" name="ud_p_r7" type="hidden" value={showRasi('rasi_7')} {...register("ud_p_r7")}/>
                                  </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box6" style={{'background':`#fff ${Constants.rasi6_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_6" style={{'color':'red'}}>{updateuser.ud_p_r6}</span>
                                      <input  id="rasi_txt_6" name="ud_p_r6" type="hidden" value={showRasi('rasi_6')} {...register("ud_p_r6")}/>
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                    </div>
                    <div className='col-xl-6 mt-4'>
                        <table width="288" height="260" border="0" align="center" cellPadding="0" cellSpacing="0">
                        <tbody>
                          <tr className="table-design lang-ta">
                            <td width="70" height="70" className="table-design lang-ta " id="rasi_box12" style={{'background':`#fff ${Constants.rasi12_Url.one} no-repeat center`,'border':'1px solid #000'}}> 
                              <span className="rasi" id="amsam_12" style={{'color':'red'}}>{updateuser.ud_p_a12}</span> 
                              <input  id="amsam_txt_12" name="ud_p_a12"  value={showAmsam('amsam_12')} type="hidden" {...register("ud_p_a12")} />
                            </td>
                            <td width="70" height="70" className="table-design lang-ta " id="rasi_box1" style={{'background':`#fff ${Constants.rasi1_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_1" style={{'color':'red'}}>{updateuser.ud_p_a1}</span>
                              <input  id="amsam_txt_1" name="ud_p_a1"  value={showAmsam('amsam_1')} type="hidden" {...register("ud_p_a1")}/>
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box2" style={{'background':`#fff ${Constants.rasi2_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_2" style={{'color':'red'}}>{updateuser.ud_p_a2}</span>
                              <input  id="amsam_txt_2" name="ud_p_a2"  value={showAmsam('amsam_2')} type="hidden" {...register("ud_p_a2")}/>
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box3" style={{'background':`#fff ${Constants.rasi3_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_3" style={{'color':'red'}}>{updateuser.ud_p_a3}</span>
                              <input  id="amsam_txt_3" name="ud_p_a3"  value={showAmsam('amsam_3')}  type="hidden" {...register("ud_p_a3")} />  
                          </td>
                          </tr>
                          <tr>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box11" style={{'background':`#fff ${Constants.rasi11_Url.one} no-repeat center`,'border':'1px solid #000'}}> 
                            <span className="rasi" id="amsam_11" style={{'color':'red'}}>{updateuser.ud_p_a11}</span> 
                            <input  id="amsam_txt_11" name="ud_p_a11"  value={showAmsam('amsam_11')} type="hidden" {...register("ud_p_a11")}/>
                              </td>
                            <td colSpan="2" rowSpan="2" align="center" valign="middle" style={{'verticalAlign':'middle', 'textAlign':'center', 'backgroundColor':'#FFF','border':'#CCC solid 1px'}}>
                              {/* <tr width="142" border="0" align="center"> */}
                                
                                  <th width="155" height="115" bgcolor="#FFFF00"><div align="center"><span className="lang-ta">etk;rk;</span></div></th>
                                
                              {/* </tr> */}
                            </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box4" style={{'background':`#fff ${Constants.rasi4_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_4" style={{'color':'red'}}>{updateuser.ud_p_a4}</span>
                              <input  id="amsam_txt_4" name="ud_p_a4"  value={showAmsam('amsam_4')} type="hidden" {...register("ud_p_a4")}/>
                            </td>
                          </tr>
                          <tr>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box10" style={{'background':`#fff ${Constants.rasi10_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_10" style={{'color':'red'}}>{updateuser.ud_p_a10}</span>
                              <input  id="amsam_txt_10" name="ud_p_a10"  value={showAmsam('amsam_10')} type="hidden" {...register("ud_p_a10")} />
                                </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box5" style={{'background':`#fff ${Constants.rasi5_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_5" style={{'color':'red'}}>{updateuser.ud_p_a5}</span>
                              <input  id="amsam_txt_5" name="ud_p_a5"  value={showAmsam('amsam_5')} type="hidden" {...register("ud_p_a5")}/>
                              </td>
                          </tr>
                          <tr>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box9" style={{'background':`#fff ${Constants.rasi9_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_9" style={{'color':'red'}}>{updateuser.ud_p_a9}</span>
                              <input  id="amsam_txt_9" name="ud_p_a9"  value={showAmsam('amsam_9')} type="hidden" {...register("ud_p_a9")}/>   
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box8" style={{'background':`#fff ${Constants.rasi8_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_8" style={{'color':'red'}}>{updateuser.ud_p_a8}</span> 
                              <input  id="amsam_txt_8" name="ud_p_a8"  value={showAmsam('amsam_8')} type="hidden" {...register("ud_p_a8")}/>
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box7" style={{'background':`#fff ${Constants.rasi7_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_7" style={{'color':'red'}}>{updateuser.ud_p_a7}</span>
                              <input  id="amsam_txt_7" name="ud_p_a7"  value={showAmsam('amsam_7')} type="hidden" {...register("ud_p_a7")}/>
                            </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box6" style={{'background':`#fff ${Constants.rasi6_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_6" style={{'color':'red'}}>{updateuser.ud_p_a6}</span>
                              <input  id="amsam_txt_6" name="ud_p_a6"  value={showAmsam('amsam_6')} type="hidden" {...register("ud_p_a6")}/>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                    </div>
                    </>
                      
                      <div>
                      {/* <form className="row pt-20" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-xl-10">
                          <div className="form-group custom-file">
                            <label className="custom-file-label low offset">{picture.name==="" ? t('horoscope_kundli') : picture.name} </label> 
                            <input type="file" onChange={onChangePicture} accept="image/*" className ="custom-file-input" />  
                            <div className="col-xl-10" style={{'marginTop':'10px'}} >
                              <span className="span-txt">{t('upload_horoscope_type')}</span>
                              <br />
                              {inputErrors===1 && <span className="error">{t('horoscope_file_type_is_invalid')}</span>}                                     
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-12 offset">
                          <div className="form-group">
                            <input type="submit" disabled={picture.name!=="" ? false : true}  value={t('submit')} style={{'background':'#dfbb26','color':'#fff'}} className="btn btn-md" />
                          </div>  
                        </div>
                      </form> */}
                    </div>
                    
			        		</div>
					        <div className="mt-4"></div>
					        
						      <h3 className="gtPageTitle text-left mt-4 ml-2">{t('edit_partner_preference')}</h3>
									<p className="gtPageSubTitle text-left mb-4 ml-2">{t('all_profiles_are_based_on_partner')}</p>
				      			<div className="gtProBox card mb-3">
				              <div className="gtProBoxHead">
				                <div className="row">
				                  <div className="col-7"><h4>{t('basic')} &amp; {t('religion_prefrence')}</h4></div>
				                  <div className="col-5 text-right">
				                    <a href="#basicPInfoModal" className="btn btn-green" data-toggle="modal">
				                    <i className="fas fa-pen mr-2"></i>{t('edit')}</a>
				                  </div>
				                </div>
				              </div>
				              <div className="gtProBoxBody">
				                <div className="row">
                          <div className="col-xl-6 col-lg-6 mb-3">
                            <div className="row">
                              <div className="col-5 gtProTxt">{t('partner_age')}</div>
                              <div className="col-7 gtProTxtDet">{myprofile.partner_age}&nbsp;&nbsp;To &nbsp;&nbsp;{myprofile.partner_age1}</div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 mb-3">
                            <div className="row">
                              <div className="col-5 gtProTxt">{t('height')}</div>
                              <div className="col-7 gtProTxtDet">{myprofile.partner_height}&nbsp;&nbsp; To  &nbsp;&nbsp;{myprofile.partner_height1}</div>
                            </div>
  				                </div>
                          <div className="col-xl-6 col-lg-6 mb-3">
                            <div className="row">
                              <div className="col-5 gtProTxt">{t('martial_status')}</div>
                              <div className="col-7 gtProTxtDet">{myprofile.partner_marital}</div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 mb-3">
                            <div className="row">
                              <div className="col-5 gtProTxt">{t('religion')}</div>
                              <div className="col-7 gtProTxtDet">{myprofile.partner_religion}</div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 mb-3">
                            <div className="row">
                              <div className="col-5 gtProTxt">{t('caste')}</div>
                              <div className="col-7 gtProTxtDet">{myprofile.partner_caste}</div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 mb-3">
                            <div className="row">
                              <div className="col-5 gtProTxt">{t('sub_caste')}</div>
                              <div className="col-7 gtProTxtDet">{myprofile.partner_subcaste}</div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 mb-3">
                            <div className="row">
                              <div className="col-5 gtProTxt">{t('mother_toungue')}</div>
                              <div className="col-7 gtProTxtDet">{myprofile.partner_toungue}</div>
                            </div>
                          </div>
                        </div>
				              </div>
				            </div>
				            <UserPartnerBasicInfoComponent userBasicInfo={partnerProfile} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
				            <div className="mt-4"></div>
					          <div className="gtProBox card mb-3">
						          <div className="gtProBoxHead">
						            <div className="row">
						              <div className="col-7"><h4>Professional Preferences</h4></div>
						              <div className="col-5 text-right"><a href="#professionalPreferencesModal" className="btn btn-green" data-toggle="modal"><i className="fas fa-pen mr-2"></i>{t('edit')}</a></div>
						            </div>
						          </div>
						          <div className="gtProBoxBody">
						            <div className="row">
						              <div className="col-xl-6 col-lg-6 mb-3">
						                <div className="row">
						                  <div className="col-5 gtProTxt"> {t('education')} </div>
						                  <div className="col-7 gtProTxtDet">{myprofile.partner_education}</div>
						                </div>
						              </div>
						              <div className="col-xl-6 col-lg-6 mb-3">
						                <div className="row">
						                  <div className="col-5 gtProTxt">{t('occupation')}</div>
						                  <div className="col-7 gtProTxtDet">{myprofile.partner_occupation}</div>
						                </div>
						              </div>
						            </div>
						            <div className="row">
						              <div className="col-xl-6 col-lg-6 mb-3">
						                <div className="row">
						                  <div className="col-5 gtProTxt">{t('employed_in')}</div>
						                  <div className="col-7 gtProTxtDet">{myprofile.partner_employee}</div>
						                </div>
						              </div>
						             </div>
						          </div>
						          <UserPartnerProInfoComponent userBasicInfo={partnerProfile} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
						        </div>
						        <div className="mt-4"></div>
						        <div className="gtProBox card mb-3">
						          <div className="gtProBoxHead">
						            <div className="row">
						              <div className="col-7"><h4>{t('location_preference')}</h4></div>
						              <div className="col-5 text-right"><a href="#locationPreferenceModal" className="btn btn-green" data-toggle="modal"><i className="fas fa-pen mr-2"></i>{t('edit')}</a></div>
						            </div>
						          </div>
						          <div className="gtProBoxBody">
						            <div className="row">
						              <div className="col-xl-6 col-lg-6 mb-3">
						                <div className="row">
						                  <div className="col-5 gtProTxt">{t('country')}</div>
						                  <div className="col-7 gtProTxtDet">{myprofile.partner_country}</div>
						                </div>
						              </div>
						              <div className="col-xl-6 col-lg-6 mb-3">
						                <div className="row">
						                  <div className="col-5 gtProTxt">{t('state')}</div>
						                  <div className="col-7 gtProTxtDet">{myprofile.partner_state}</div>
						                </div>
						              </div>
						            </div>
						            <div className="row">
						              <div className="col-xl-6 col-lg-6 mb-3">
						                <div className="row">
						                  <div className="col-5 gtProTxt">{t('city')}</div>
						                  <div className="col-7 gtProTxtDet">{myprofile.partner_city}</div>
						                </div>
						              </div>
						            </div>
						          </div>
						          <UserPartnerLocComponent  userBasicInfo={partnerProfile} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
						        </div>
						        <div className="mt-4"></div>
	        					<div className="gtProBox card mb-3">
							        <div className="gtProBoxHead">
							          <div className="row">
							            <div className="col-7"><h4>{t('partner_preference')}</h4></div>
							            <div className="col-5 text-right"><a href="#partnerPreferenceModal" className="btn btn-green" data-toggle="modal" data-backdrop="static"><i className="fas fa-pen mr-2"></i>{t('edit')}</a></div>
							          </div>
							        </div>
							        <div className="gtProBoxBody">
							          <div className="row mb-3">
							            <div className="col-xl-12 gtProTxtDet">
							              <p>{myprofile.partner_expectation}</p>
							            </div>
							          </div>
							        </div>
							        <UserPartnerPrefComponent  userBasicInfo={partnerProfile} issubmit={issubmit}  setIsSubmit={setIsSubmit}  />
							      </div>
				      		</div>
				      	</div>
	        	  </div>
	        	</div>
   )
}