import React, { useState,useEffect,useCallback } from 'react';
import { Link,useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";
import Axios from "axios";
import ReactPaginate from 'react-paginate';
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function ListsearchuserComponenet({search,page,searchid,loading,setLoading}) {
  const itemsPerPage = page ? page : 5;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [users,setUsers] = useState({});
  const [width,setWidth] = useState({size:0,data:0});
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  let userPaid =  parseInt(localStorage.getItem('userPaid'));
  let userGender =  parseInt(localStorage.getItem('userGender'));
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  let userTransaction  = parseInt(localStorage.getItem('userTransaction'));

  if(window.innerWidth >= 481 && window.innerWidth <= 768 && width.size===0){
    setWidth({...width,size:1});
  }

  if(window.innerWidth >= 769 && window.innerWidth <= 1024 && width.size===0){
    setWidth({...width,size:2});
  }

  if(window.innerWidth >= 1050 && window.innerWidth <= 1400 && width.size===0){
    setWidth({...width,size:3});
  }

  const searchData = useCallback(() => {
    if(search!==null){
      let data = search;
      setLoading(0);
      Axios.post(Constants.API_URL+"profilesearch",{
        matri_id: data.matri_id,gender : data.gender,asc_age : data.asc_age,
        desc_age : data.desc_age,asc_height : data.asc_height,desc_height : data.desc_height,
        marital_status : data.marital_status,religion : data.religion,
        caste : data.caste, subcaste : data.subcaste,country : data.country,
        state : data.state , city : data.city,mother_tongue : data.mother_tongue,
        education : data.education,occupation : data.occupation,
        dhosa : data.dhosa }).then((response)=>{
        setLoading(1);
          if(response.data.data.length > 0){
            setUsers(response.data.data);
          }
          if(response.data.data.length === 0){
            setUsers(response.data.data);
          }
      }).catch((err) => {
        console.log(err);
    })
    }else{
      if(searchid!==null){
        setLoading(0);
        Axios.post(Constants.API_URL+"profilesearch",{matri_id:searchid}).then((response)=>{
          setLoading(1);
          if(response.data.data.length === 0){
            setUsers(response.data.data);
          }
          if(response.data.data.length > 0 ){
            setUsers(response.data.data);
            if(response.data.data[0].gender){
              let gender = response.data.data[0].gender==="Male" ? 1 :  2;
              if(gender===userGender){
                navigate('/search',{state:{title:true,message:t('access_is_denied'),error:true}});
              }
            }
          }
        }).catch((err) => {
          console.log(err);
      })
      }
    }
  }, [search,searchid,navigate,setLoading,t,userGender]);

  useEffect(() => {
      searchData();
  },[search,searchid,searchData])

  useEffect(() => {
    if(users.length > 0){
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(users.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(users.length / itemsPerPage));
    }else{
      setCurrentItems([]);
    }
  }, [itemOffset, itemsPerPage,users]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    setItemOffset(newOffset);
  };

  const maritalStatusMap = {
    1: 'Never Married',
    2: 'Divorced',
    3: 'Separated',
    4: 'Widow',
    5: 'Widower',
    
};

const getMaritalStatus = (status) => {
    return maritalStatusMap[status] || 'Unknown';
};



   return (
    <div>
    {currentItems===null &&  <ResultNotFoundComponent data={width.size} />}
    {(currentItems!==null && currentItems.length > 0) && currentItems.map((user,index) => (
      <div className="gtMainResult mb-3" key={user.id}>
        <div className="row">
          <div className="col-xl-3 col-12 col-md-3 col-lg-4">
            {(((user.photoprivacy===2 && userPaid===1) || (user.photoprivacy===1 && userToken!==null)) && user.photoprivacy!==0) && 
                <img alt={user.matri_id} style={{'minHeight':'180px'}}  className="img-fluid"
                src={user.image!=="" ? Constants.IMAGE_URL+user.image : (user.gender!=="Female" ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png'))} />
              }
              {((user.photoprivacy===0 && userToken!==null) || (user.photoprivacy===2 && userPaid===0) || userToken===null) && 
                <img alt={user.matri_id} style={{'minHeight':'180px','minWidth':'121%'}} 
                src={user.gender==="Male" ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png')} />
              }
          </div>
          <div className="col-xl-8 col-12 col-md-9 col-lg-8 pt-2 ml-4">
            <div className="w-100 gtMainResFir gtXSP15 gtSMP15">
            {(userPaid===0 || userToken===null) && 
              <Link to={userToken!==null ? "/memberplan" : "/login"}
              state={{title:true,error:true,message:(userPaid===0 ? ((userTransaction!==0) ? t('please_contact_support') : t('please_add_a_membership')) : t('please_login_to_continue'))}}>
                <h3>{user.fname} {user.lname}</h3>
                <h5>{user.matri_id}</h5>
              </Link>
            }
            {(userPaid===1 && userToken!==null) && 
               <Link to={`/viewprofile/${user.id}`}>
                 <h3>{user.fname} {user.lname}</h3>
                 <h5>{user.matri_id}</h5>
              </Link>
            }
            </div>
            <div className="w-100 gtMainResSec gtXSP15 gtSMP15">
              <div className="row mb5">
                <div className="col-4 gtResTag">{t('age')} / {t('height')} </div>
                <div className="col-8 gtResDet">{user.age}{t('years')} / 5ft 6in - 167cm</div>
              </div>
              <div className="row mb5">
                <div className="col-4 gtResTag">{t('religion')}</div>
                <div className="col-8 gtResDet">{user.religion}</div>
              </div>
              <div className="row mb5">
                <div className="col-4 gtResTag">{t('caste')}</div>
                <div className="col-8 gtResDet">{user.caste}</div>
              </div>
              <div className="row mb5">
                <div className="col-4 gtResTag">{t('country')}</div>
                <div className="col-8 gtResDet">{user.country}</div>
              </div>
              <div className="row mb5">
                <div className="col-4 gtResTag">{t('martial_status')}</div>
                <div className="col-8 gtResDet">{getMaritalStatus(user.marital_status)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ))}
      {users.length > 5 &&
      <div style={{'float':'right'}}>
        <ReactPaginate
        previousLabel={"<< Previous"}
        nextLabel={"Next >>"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
      </div>
      }
    </div>
  )
}