import { useEffect,useState,useCallback } from "react";
import { useForm,Controller } from 'react-hook-form';
import Axios from "axios"
import Select from 'react-select';
import Constants from "../services/Constants";
import $ from "jquery";

export default function  UserJathagamInfoModal({userBasicInfo,issubmit,setIsSubmit}){
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { register,handleSubmit,getValues ,setValue , control } = useForm({mode : "onChange",defaultValue:{
   ud_star : 0,ud_rasi :0,
    ud_laknum : 0,ud_birthtime : "00:00:00",ud_birthplace : "",
      ud_dosh : 0,ud_dhisa : 0,ud_dhisa_date : 0,ud_dhisa_month : 0,ud_dhisa_year : 0 
  }});
 const [rasiSelect,setRasiSelect] = useState({'rasi_1':[],'rasi_2':[],'rasi_3':[],'rasi_4':[],'rasi_5':[],'rasi_6':[],'rasi_7':[],'rasi_8':[],'rasi_9':[],'rasi_10':[],'rasi_11':[],'rasi_12':[]});
 const [amsamSelect,setAmsamSelect] = useState({'amsam_1':[],'amsam_2':[],'amsam_3':[],'amsam_4':[],'amsam_5':[],'amsam_6':[],'amsam_7':[],'amsam_8':[],'amsam_9':[],'amsam_10':[],'amsam_11':[],'amsam_12':[]});

  const onBasicSubmit = (data) => {
    Axios.post(Constants.API_URL+"postmyprofile",{
        api_token : userToken,ud_star:data.ud_star,
        ud_rasi :data.ud_rasi,ud_laknum : data.ud_laknum,
        ud_birthtime : data.ud_birthtime,ud_birthplace : data.ud_birthplace,
        ud_dosh : data.ud_dosh,ud_manglik : data.ud_manglik,ud_dhisa : data.ud_dhisa,
        ud_dhisa_date : data.ud_dhisa_date,ud_dhisa_month : data.ud_dhisa_month,
        ud_dhisa_year : data.ud_dhisa_year,ud_birthgod : data.ud_birthgod,
        ud_gothram : data.ud_gothram,ud_poorvigam : data.ud_poorvigam,
        ud_p_r1: rasiSelect.rasi_1.join(' '),ud_p_r2: rasiSelect.rasi_2.join(' '),ud_p_r3: rasiSelect.rasi_3.join(' '),ud_p_r4: rasiSelect.rasi_4.join(' '),ud_p_r5: rasiSelect.rasi_5.join(' '),ud_p_r6: rasiSelect.rasi_6.join(' '),
        ud_p_r7: rasiSelect.rasi_7.join(' '),ud_p_r8: rasiSelect.rasi_8.join(' '),ud_p_r9: rasiSelect.rasi_9.join(' '),ud_p_r10: rasiSelect.rasi_10.join(' '),ud_p_r11: rasiSelect.rasi_11.join(' '),ud_p_r12: rasiSelect.rasi_12.join(' '),
         ud_p_a1: amsamSelect.amsam_1.join(' '),ud_p_a2: amsamSelect.amsam_2.join(' '),ud_p_a3: amsamSelect.amsam_3.join(' '),ud_p_a4: amsamSelect.amsam_4.join(' '),ud_p_a5: amsamSelect.amsam_5.join(' '),ud_p_a6: amsamSelect.amsam_6.join(' '),
        ud_p_a7: amsamSelect.amsam_7.join(' '),ud_p_a8: amsamSelect.amsam_8.join(' '),ud_p_a9: amsamSelect.amsam_9.join(' '),ud_p_a10: amsamSelect.amsam_10.join(' '),ud_p_a11: amsamSelect.amsam_11.join(' '),ud_p_a12: amsamSelect.amsam_12.join(' ')
      }).then((response)=>{
        setIsSubmit('');
        setIsSubmit(response.data.error+'/'+response.data.message);
        $(".closeProfModal").click();
      }).catch((err) => {
        console.log(err);
      })
  }

  const setZodiacsymbol = useCallback(() => {
      let updatedRasiSelect = { ...rasiSelect };
      let updatedAmsamSelect = { ...amsamSelect };
      for (let i=1;i<13;i++){
      const rasiKey = 'rasi_' + i;
      const amsamKey = 'amsam_' + i;
        if (("ud_p_r" + i) in userBasicInfo){
          updatedRasiSelect = {...updatedRasiSelect, [rasiKey]: userBasicInfo['ud_p_r' + i].split(" ")};
        }
        if (("ud_p_a" + i) in userBasicInfo){
          updatedAmsamSelect = {...updatedAmsamSelect,[amsamKey]: userBasicInfo['ud_p_a' + i].split(" ")};
        }
      }
      setRasiSelect(updatedRasiSelect);
      setAmsamSelect(updatedAmsamSelect);
  },[userBasicInfo]);

  useEffect(() => { setZodiacsymbol(); },[setZodiacsymbol]);

  useEffect(() => { 
    if(userBasicInfo!=null){
      setValue('ud_rasi',userBasicInfo.ud_rasi);
      setValue('ud_laknum',userBasicInfo.ud_laknum);
      setValue('ud_star',userBasicInfo.ud_star);
      setValue('ud_birthtime',userBasicInfo.ud_birthtime);
      setValue('ud_birthplace',userBasicInfo.ud_birthplace);
      setValue('ud_birthgod',userBasicInfo.ud_birthgod);
      setValue('ud_gothram',userBasicInfo.ud_gothram);
      setValue('ud_poorvigam',userBasicInfo.ud_poorvigam);
      setValue('ud_dosh',userBasicInfo.ud_dosh);
      setValue('ud_dhisa',parseInt(userBasicInfo.ud_dhisa));
      setValue('ud_manglik',parseInt(userBasicInfo.ud_manglik));
      setValue('ud_dhisa_date',parseInt(userBasicInfo.ud_dhisa_date));
      setValue('ud_dhisa_month',parseInt(userBasicInfo.ud_dhisa_month));
      setValue('ud_dhisa_year',parseInt(userBasicInfo.ud_dhisa_year));
    }
  },[userBasicInfo,setValue]);
  
  function genstar(value,name,number){
    let arr = [],updateArr = [];
    var rasi  = 'rasi_'+number;
    if(number!==''){
      if(rasiSelect[rasi].length>0) updateArr = rasiSelect[rasi];
    }
    for (let i=1;i<13;i++){
      const dynamicRasi = 'rasi_'+i;
      if(rasiSelect[dynamicRasi].includes(value)){   
        setRasiSelect(previousState => { return {...previousState, [dynamicRasi] : rasiSelect[dynamicRasi].filter((item) => item !== value)} });
      }
    }
    if(updateArr.length===0) arr.push(value);
    else if(!updateArr.includes(value))arr = updateArr.push(value);
    if(number!=='')
      setRasiSelect(previousState => { return {...previousState, [rasi] : rasiSelect[rasi].length===0 ? arr : updateArr} });
    }

     const showRasi = (value) =>{
      let split = '';
      if(rasiSelect[value].length>=0){
        split = rasiSelect[value].join(" ");
        return split;
      }else{
        return 0;
      }
    }

  function genamsam(value,name,number){
    let arr = [],updateArr = [];
    var amsam  = 'amsam_'+number;
    if(number!==''){
      if(amsamSelect[amsam].length>0) updateArr = amsamSelect[amsam];
    }
    for (let i=1;i<13;i++){
      const dynamicAmsam = 'amsam_'+i;
      
      if(amsamSelect[dynamicAmsam].includes(value)){   
        setAmsamSelect(previousState => { return {...previousState, [dynamicAmsam] : amsamSelect[dynamicAmsam].filter((item) => item !== value)} });
      }
    }
    if(updateArr.length===0) arr.push(value);
    else if(!updateArr.includes(value))arr = updateArr.push(value);
    if(number!=='')
      setAmsamSelect(previousState => { return {...previousState, [amsam] : amsamSelect[amsam].length===0 ? arr : updateArr} });
    }

     const showAmsam = (value) =>{
    let split = '';
    if(amsamSelect[value].length>=0){
      split = amsamSelect[value].join(" ");
      return split;
    }else{
      return 0;
    }
    
  }


  return (
      <div className="modal fade gtEditForm" id="userJathagamInfoModal" tabIndex="-1" role="dialog" aria-labelledby="userJathagamInfoModal" aria-hidden="true" data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <div className="col-12">
                <h5 className="modal-title" id="exampleModalLabel">Zodiac Information
                <button type="button" className="close closeProfModal" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                </h5>
              </div>
            </div>
            <div className="modal-body modalBodyHeight">
              <div className="row">
                 <form className="form-horizontal" onSubmit={handleSubmit(onBasicSubmit)}>  
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label>Rasi (Moon sign)</label>
                          {Constants.getUserRasiMoonSign && (
                                <Controller
                                 name="ud_rasi"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getUserRasiMoonSign.find((c) => c.value === value) || ''}
                                    options={Constants.getUserRasiMoonSign}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}     
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label>Star (Nakshatra)</label>
                          {Constants.getUserStarNakshatra && (
                                <Controller
                                 name="ud_star"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getUserStarNakshatra.find((c) => c.value === value) || ''}
                                    options={Constants.getUserStarNakshatra}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}  
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label>Lagnum</label>  
                          {Constants.getUserRasiMoonSign && (
                                <Controller
                                 name="ud_laknum"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getUserRasiMoonSign.find((c) => c.value === value) || ''}
                                    options={Constants.getUserRasiMoonSign}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}             
                          
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label>Birth Time</label>           
                          <input type='time' placeholder="Enter The Birth Time" className="form-control"
                      {...register("ud_birthtime"
                    )}  />       
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label>Birth Place </label>            
                           <input type='input' placeholder="Enter The Birth Place" className="form-control"
                      {...register("ud_birthplace")}  />    
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label>Birth God (Kuladeivam) </label>            
                           <input type='input' placeholder="Enter The Birth God" className="form-control"
                      {...register("ud_birthgod")}  />    
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label>Gothram </label>            
                           <input type='input' placeholder="Enter The Gothram" className="form-control"
                      {...register("ud_gothram")}  />    
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label>Poorvigam </label>            
                           <input type='input' placeholder="Enter The Poorvigam" className="form-control"
                      {...register("ud_poorvigam")}  />    
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label className=" required">Dhosa</label> 
                           {Constants.getUserDhosa && (
                                <Controller
                                 name="ud_dosh"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getUserDhosa.find((c) => c.value === value) || ''}
                                    options={Constants.getUserDhosa}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}              
                        </div>
                      </div>

                            {/* {(userBasicInfo.ud_dosh === 2 || getValues('ud_dosh') === 2) && (
                              <div className="col-xl-12">
                                <div className="form-group">
                                  <label className="required">Manglik Dhosam</label>
                                  {Constants.getManglikDhosam && (
                                    <Controller
                                      name="ud_manglik"
                                      control={control}
                                      render={({ field: { onChange, value, name, ref } }) => (
                                        <Select
                                          inputRef={ref}
                                          className="mb-4"
                                          value={Constants.getManglikDhosam.find((c) => c.value === value) || ''}
                                          options={Constants.getManglikDhosam}
                                          onChange={(val: ICategory) => {
                                            onChange(val.value);
                                          }}
                                          
                                        />
                                        
                                      )}
                                      
                                    />
                                  )}
                                </div>
                              </div>
                            )} */}


                      <div className="col-xl-12">
                        <div className="form-group">
                         <label className=" required">Thisai irruppu</label>          
                          {Constants.getThisaiIruppu && (
                                <Controller
                                 name="ud_dhisa"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getThisaiIruppu.find((c) => c.value === value) || ''}
                                    options={Constants.getThisaiIruppu}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}  
                        </div>
                      </div>
                      <div className="col-xl-4">
                      <div className="form-group">
                      <label >Dhisa Day</label>
                      {Constants.getDay && (
                                <Controller
                                 name="ud_dhisa_date"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getDay.find((c) => c.value === value) || ''}
                                    options={Constants.getDay}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}  
                         
                    </div>
                      </div>
                      <div className="col-xl-4">
                      <div className="form-group">
                      <label >Dhisa Month</label>
                      {Constants.getMonth && (
                                <Controller
                                 name="ud_dhisa_month"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getMonth.find((c) => c.value === value) || ''}
                                    options={Constants.getMonth}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}  
                    </div>
                      </div>
                      <div className="col-xl-4">
                      <div className="form-group">
                      <label>Dhisa Year</label>
                       {Constants.getYear && (
                                <Controller
                                 name="ud_dhisa_year"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getYear.find((c) => c.value === value) || ''}
                                    options={Constants.getYear}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}  
                    </div>
                      </div>
                      <div className="col-xl-12">
                      <div className='row'>
                        <div className='col-xl-4'>
                        <label className="lang-ta">yf;fdk;</label>
                        <Select options={Constants.getUserZodiacNO}   title="Choose lagnam" name="rasi_select1" id="rasi_select1" onChange={(e) => genstar('y','rasi_1',e.value)} >
                        </Select>
                        </div>
                        <div className='col-xl-4'>
                        <label className="lang-ta">#hpad;</label>
                        <Select options={Constants.getUserZodiacNO}  name="rasi_select2" title="Choose Suriyan" id="rasi_select2" onChange={(e) => genstar('#','rasi_2',e.value)} >
                        </Select>
                        </div>
                        <div className='col-xl-4'>
                        <label className="lang-ta">re;jpud;</label>
                        <Select options={Constants.getUserZodiacNO} name="rasi_select3" title="Choose Chandran" id="rasi_select3" onChange={(e) => genstar('re;','rasi_3',e.value)} >
                        </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">nrt;tha;</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select4" title="Choose Sevvai" id="rasi_select4" onChange={(e) => genstar('nr','rasi_4',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">Gjd;</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select5" title="Choose Budhan" id="rasi_select5" onChange={(e) => genstar('G','rasi_5',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">FU</label>
                          <Select options={Constants.getUserZodiacNO} name="rasi_select6" title="Choose Guru" id="rasi_select6" onChange={(e) => genstar('F','rasi_6',e.value)} >
                           </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">Rf;fpud;</label>
                          <Select options={Constants.getUserZodiacNO} name="rasi_select7" title="Choose Sukran" id="rasi_select7" onChange={(e) => genstar('R','rasi_7',e.value)} >
                           </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">rdp</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select8" title="Choose sani" id="rasi_select8" onChange={(e) => genstar('rdp','rasi_8',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">uhF</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select9" title="Choose Rahu" id="rasi_select9" onChange={(e) =>genstar('uh','rasi_9',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">NfJ</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select10" title="Choose Kedhu" id="rasi_select10" onChange={(e) => genstar('Nf','rasi_10',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">khe;jp</label>
                          <Select options={Constants.getUserZodiacNO} name="rasi_select11" title="Choose Mandhi" id="rasi_select11" onChange={(e) => genstar('kh','rasi_11',e.value)} >
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 mt-4 mb-4">
                    <div className="row">
                    <table width="288" height="260" border="0" align="center" cellPadding="0" cellSpacing="0">
                              <tbody>
                                <tr className="table-design lang-ta">
                                  <td width="70" height="70" className="table-design lang-ta " id="rasi_box12" style={{'background':`#fff ${Constants.rasi12_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_12"style={{'color':'red'}}>
                                      {showRasi('rasi_12')}
                                        </span> 
                                      <input  id="rasi_txt_12" name="ud_p_r12" type="hidden" value={showRasi('rasi_12')} {...register("ud_p_r12")}/>
                                  </td>
                                  <td width="70" height="70" className="table-design lang-ta " id="rasi_box1" style={{'background':`#fff ${Constants.rasi1_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_1" style={{'color':'red'}}>{showRasi('rasi_1')}</span> 
                                      <input  id="rasi_txt_1" name="ud_p_r1" type="hidden" value={showRasi('rasi_1')} {...register("ud_p_r1")} />
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box2" style={{'background':`#fff ${Constants.rasi2_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_2" style={{'color':'red'}}>{showRasi('rasi_2')}</span> 
                                    <input  id="rasi_txt_2" name="ud_p_r2" type="hidden" value={showRasi('rasi_2')} {...register("ud_p_r2")} />
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box3" style={{'background':`#fff ${Constants.rasi3_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_3" style={{'color':'red'}}>{showRasi('rasi_3')}</span> 
                                    <input  id="rasi_txt_3" name="ud_p_r3" type="hidden" value={showRasi('rasi_3')} {...register("ud_p_r3")} />
                                </td>
                                </tr>
                                <tr>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box11" style={{'background':`#fff ${Constants.rasi11_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_11" style={{'color':'red'}}>{showRasi('rasi_11')}</span> 
                                    <input  id="rasi_txt_11" name="ud_p_r11" type="hidden" value={showRasi('rasi_11')} {...register("ud_p_r11")} />
                                  </td>
                                  <td colSpan="2" rowSpan="2" align="center" valign="middle" style={{'verticalAlign':'middle','textAlign':'center','backgroundColor':'#FFF' ,'border':'#CCC solid 1px'}}>
                                    <tr width="142" border="0" align="center">
                                      
                                        <td width="155" height="115" bgcolor="#FFFF00"><div align="center"><span className="lang-ta">uhrp</span></div></td>
                                      
                                    </tr>
                                  </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box4" style={{'background':`#fff ${Constants.rasi4_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_4" style={{'color':'red'}}>{showRasi('rasi_4')}</span>
                                    <input  id="rasi_txt_4" name="ud_p_r4" type="hidden" value={showRasi('rasi_4')} {...register("ud_p_r4")} />
                                  </td>
                                </tr>
                                <tr>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box10" style={{'background':`#fff ${Constants.rasi10_Url.one} no-repeat center`,'border':'1px solid #000'}}> 
                                      <span className="rasi" id="rasi_10" style={{'color':'red'}}>{showRasi('rasi_10')}</span> 
                                      <input  id="rasi_txt_10" name="ud_p_r10" type="hidden" value={showRasi('rasi_10')} {...register("ud_p_r10")} />
                                      </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box5" style={{'background':`#fff ${Constants.rasi5_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_5" style={{'color':'red'}}>{showRasi('rasi_5')}</span> 
                                      <input  id="rasi_txt_5" name="ud_p_r5" type="hidden" value={showRasi('rasi_5')} {...register("ud_p_r5")} />
                                    </td>
                                </tr>
                                <tr>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box9" style={{'background':`#fff ${Constants.rasi9_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_9" style={{'color':'red'}}>{showRasi('rasi_9')}</span> 
                                      <input  id="rasi_txt_9" name="ud_p_r9" type="hidden" value={showRasi('rasi_9')} {...register("ud_p_r9")}/>
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box8" style={{'background':`#fff ${Constants.rasi8_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_8" style={{'color':'red'}}>{showRasi('rasi_8')}</span>
                                      <input  id="rasi_txt_8" name="ud_p_r8" type="hidden" value={showRasi('rasi_8')} {...register("ud_p_r8")}/>
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box7" style={{'background':`#fff ${Constants.rasi7_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_7" style={{'color':'red'}}>{showRasi('rasi_7')}</span>
                                      <input  id="rasi_txt_7" name="ud_p_r7" type="hidden" value={showRasi('rasi_7')} {...register("ud_p_r7")}/>
                                  </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box6" style={{'background':`#fff ${Constants.rasi6_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_6" style={{'color':'red'}}>{showRasi('rasi_6')}</span>
                                      <input  id="rasi_txt_6" name="ud_p_r6" type="hidden" value={showRasi('rasi_6')} {...register("ud_p_r6")}/>
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                          </div>
                          </div>
                          <div className='col-xl-12'>
                      <div className='row'>
                        <div className='col-xl-4'>
                          <label className="lang-ta">yf;fdk;</label>
                          <Select options={Constants.getUserZodiacNO}   title="Choose lagnam" name="amsam_select1" id="amsam_select1" onChange={(e) => genamsam('y','amsam_select1',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">#hpad;</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select2" title="Choose Suriyan" id="amsam_select2" onChange={(e) => genamsam('#','amsam_select2',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">re;jpud;</label>
                          <Select options={Constants.getUserZodiacNO} name="amsam_select3" title="Choose Chandran" id="amsam_select3" onChange={(e) => genamsam('re;','amsam_select3',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">nrt;tha;</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select4" title="Choose Sevvai" id="amsam_select4" onChange={(e) => genamsam('nr','amsam_select4',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">Gjd;</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select5" title="Choose Budhan" id="amsam_select5" onChange={(e) =>genamsam('G','amsam_select5',e.value)} >
                          </Select> 
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">FU</label>
                          <Select options={Constants.getUserZodiacNO} name="amsam_select6" title="Choose Guru" id="amsam_select6" onChange={(e) =>genamsam('F','amsam_select6',e.value)} >
                           </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">Rf;fpud;</label>
                          <Select options={Constants.getUserZodiacNO} name="amsam_select7" title="Choose Sukran" id="amsam_select7" onChange={(e) => genamsam('R','amsam_select7',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">rdp</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select8" title="Choose sani" id="amsam_select8" onChange={(e) => genamsam('rdp','amsam_select8',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">uhF</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select9" title="Choose Rahu" id="amsam_select9" onChange={(e) => genamsam('uh','amsam_select9',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">NfJ</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select10" title="Choose Kedhu" id="amsam_select10" onChange={(e) => genamsam('Nf','amsam_select10',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-4'>
                          <label className="lang-ta">khe;jp</label>
                          <Select options={Constants.getUserZodiacNO} name="amsam_select11" title="Choose Mandhi" id="amsam_select11" onChange={(e) => genamsam('kh','amsam_select11',e.value)} >
                          </Select>
                        </div>
                        </div>
                    </div>
                    <div className="col-xl-12 mt-4 mb-4">
                    <div className="row">
                        <table width="288" height="260" border="0" align="center" cellPadding="0" cellSpacing="0">
                        <tbody>
                          <tr className="table-design lang-ta">
                            <td width="70" height="70" className="table-design lang-ta " id="rasi_box12" style={{'background':`#fff ${Constants.rasi12_Url.one} no-repeat center`,'border':'1px solid #000'}}> 
                              <span className="rasi" id="amsam_12" style={{'color':'red'}}>{showAmsam('amsam_12')}</span> 
                              <input  id="amsam_txt_12" name="ud_p_a12"  value={showAmsam('amsam_12')} type="hidden" {...register("ud_p_a12")} />
                            </td>
                            <td width="70" height="70" className="table-design lang-ta " id="rasi_box1" style={{'background':`#fff ${Constants.rasi1_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_1" style={{'color':'red'}}>{showAmsam('amsam_1')}</span>
                              <input  id="amsam_txt_1" name="ud_p_a1"  value={showAmsam('amsam_1')} type="hidden" {...register("ud_p_a1")}/>
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box2" style={{'background':`#fff ${Constants.rasi2_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_2" style={{'color':'red'}}>{showAmsam('amsam_2')}</span>
                              <input  id="amsam_txt_2" name="ud_p_a2"  value={showAmsam('amsam_2')} type="hidden" {...register("ud_p_a2")}/>
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box3" style={{'background':`#fff ${Constants.rasi3_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_3" style={{'color':'red'}}>{showAmsam('amsam_3')}</span>
                              <input  id="amsam_txt_3" name="ud_p_a3"  value={showAmsam('amsam_3')}  type="hidden" {...register("ud_p_a3")} />  
                          </td>
                          </tr>
                          <tr>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box11" style={{'background':`#fff ${Constants.rasi11_Url.one} no-repeat center`,'border':'1px solid #000'}}> 
                            <span className="rasi" id="amsam_11" style={{'color':'red'}}>{showAmsam('amsam_11')}</span> 
                            <input  id="amsam_txt_11" name="ud_p_a11"  value={showAmsam('amsam_11')} type="hidden" {...register("ud_p_a11")}/>
                              </td>
                            <td colSpan="2" rowSpan="2" align="center" valign="middle" style={{'verticalAlign':'middle', 'textAlign':'center', 'backgroundColor':'#FFF','border':'#CCC solid 1px'}}>
                              {/* <tr width="142" border="0" align="center"> */}
                                
                                  <th width="155" height="115" bgcolor="#FFFF00"><div align="center"><span className="lang-ta">etk;rk;</span></div></th>
                                
                              {/* </tr> */}
                            </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box4" style={{'background':`#fff ${Constants.rasi4_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_4" style={{'color':'red'}}>{showAmsam('amsam_4')}</span>
                              <input  id="amsam_txt_4" name="ud_p_a4"  value={showAmsam('amsam_4')} type="hidden" {...register("ud_p_a4")}/>
                            </td>
                          </tr>
                          <tr>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box10" style={{'background':`#fff ${Constants.rasi10_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_10" style={{'color':'red'}}>{showAmsam('amsam_10')}</span>
                              <input  id="amsam_txt_10" name="ud_p_a10"  value={showAmsam('amsam_10')} type="hidden" {...register("ud_p_a10")} />
                                </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box5" style={{'background':`#fff ${Constants.rasi5_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_5" style={{'color':'red'}}>{showAmsam('amsam_5')}</span>
                              <input  id="amsam_txt_5" name="ud_p_a5"  value={showAmsam('amsam_5')} type="hidden" {...register("ud_p_a5")}/>
                              </td>
                          </tr>
                          <tr>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box9" style={{'background':`#fff ${Constants.rasi9_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_9" style={{'color':'red'}}>{showAmsam('amsam_9')}</span>
                              <input  id="amsam_txt_9" name="ud_p_a9"  value={showAmsam('amsam_9')} type="hidden" {...register("ud_p_a9")}/>   
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box8" style={{'background':`#fff ${Constants.rasi8_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_8" style={{'color':'red'}}>{showAmsam('amsam_8')}</span> 
                              <input  id="amsam_txt_8" name="ud_p_a8"  value={showAmsam('amsam_8')} type="hidden" {...register("ud_p_a8")}/>
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box7" style={{'background':`#fff ${Constants.rasi7_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_7" style={{'color':'red'}}>{showAmsam('amsam_7')}</span>
                              <input  id="amsam_txt_7" name="ud_p_a7"  value={showAmsam('amsam_7')} type="hidden" {...register("ud_p_a7")}/>
                            </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box6" style={{'background':`#fff ${Constants.rasi6_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_6" style={{'color':'red'}}>{showAmsam('amsam_6')}</span>
                              <input  id="amsam_txt_6" name="ud_p_a6"  value={showAmsam('amsam_6')} type="hidden" {...register("ud_p_a6")}/>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                    </div>
                      <div className="col-xl-12">
                        <div className="form-group text-center">
                          <input className="btn btnRegFsubmit" type="submit" value="Update" />           
                        </div>
                      </div>
                    </div>
                  </div>
                </form>        
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}