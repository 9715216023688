import React, { useEffect,useState } from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from "axios"
import Select from 'react-select';
import Constants from "../services/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default function RegiterOneComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const setting=JSON.parse(localStorage.getItem('setting'));
  const { register, handleSubmit,setError,setValue, formState: { errors } } = useForm();
	const [passwordType, setPasswordType] = useState("password");
	const [confirmpasswordType, setConfirmpasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmpasswordInput, setConfirmpasswordInput] = useState("");
  const [getReligionData,setgetReligion] = useState([]);
  const [getCountryData,setgetCountry] = useState([]);
  const [getMotherToungueData,setgetMotherTongue] = useState([]);
  const [getCountryCode,setgetCountryCode] = useState([]);
  const [issubmit,setIsSubmit]= useState(0);
  const [picture, setPicture] = useState({file:{},name:""});
  const [dateOfBirth,setDateOfBirth]= useState(new Date());
  const [inputValue,setInputValues] = useState({
  		religionID : 0, casteID : 0,subCasteID: 0, countryID : 0, stateID : 0,cityID : 0, profileCreatedBy : 0, gender : 0, 
  		countryCodeID : 0,martialStatus : 0,noofChildren : 0,childrenStatus : 0,motherToungue : 0,height : 0,
  		star : 0,rasi :0, dhosa : 0,ud_dhisa : 0,password : 0,laknum : 0,thisaDay : 0,thisaMonth : 0,thisaYear : 0 });
  const [inputErrors,setInputErrors]= useState({
  		religion :"", caste : "",subcaste : "", country : "", state : "", city : "",
  	  profilecreatedfor : "", gender : "", countrycode : "",martialStatus : "", 
  	  motherToungue : "", height : "",password : "",dateOfBirth : "",horoscopePic : ""});
  const [datas,setDatas] = useState({caste:[],subcaste:[],state:[],city:[]});
  
  const [rasiSelect,setRasiSelect] = useState({'rasi_1':[],'rasi_2':[],'rasi_3':[],'rasi_4':[],'rasi_5':[],'rasi_6':[],'rasi_7':[],'rasi_8':[],'rasi_9':[],'rasi_10':[],'rasi_11':[],'rasi_12':[]});
  const [amsamSelect,setAmsamSelect] = useState({'amsam_1':[],'amsam_2':[],'amsam_3':[],'amsam_4':[],'amsam_5':[],'amsam_6':[],'amsam_7':[],'amsam_8':[],'amsam_9':[],'amsam_10':[],'amsam_11':[],'amsam_12':[]});
  let mobile;


  if(!localStorage.getItem("registermobile")){
    mobile = '';
  }else{
    mobile = localStorage.getItem("registermobile")
  }

  

   useEffect(() => {
    Axios.get(Constants.API_URL+"getdropdowndata").then((response)=>{
        if(response.data.data){
          setgetReligion(response.data.data.getReligion);
          setgetCountry(response.data.data.getCountry);
          setgetMotherTongue(response.data.data.getMotherToungue);
          setgetCountryCode(response.data.data.getCountryCode);
        }
      }).catch(errors => {
          console.log(errors);
      });
  },[]);

   useEffect(() => {  
   },[inputValue.dhosa]);
  
   useEffect(() => {  
  },[inputValue.martialStatus]);

 

	useEffect(() => {
    if(location.state!=null){
      if(location.state.title!==null){
    		var title = location.state.title;
  	    if(title===true){
  	    	if(location.state.error===true) toast.error(location.state.message);
          else toast.success(location.state.message);
          if(location.state.title) navigate(location.pathname, { replace: true });
  	    }
      }
    }
	},[location,navigate]);

  const onSubmit = (data) => {
    console.log(data);
    if(data.ud_password === data.ud_password_repeat){
      Axios.post(Constants.API_URL+"createuser",{
        dosh : inputValue.dhosa,
        ud_id: localStorage.getItem("registerid"), ud_firstname: data.ud_firstname,
        ud_lastname: data.ud_lastname, ud_email: data.ud_email,ud_password: data.ud_password,
        ud_phonecode: inputValue.countryCodeID,ud_phone: localStorage.getItem("registermobile"), 
        ud_profile: inputValue.profileCreatedBy ,ud_gender: inputValue.gender , 
        ud_dob : moment(new Date(dateOfBirth)).format('YYYY-MM-DD'),ud_marital: inputValue.martialStatus, 
        ud_child: inputValue.noofChildren ,ud_childstatus: inputValue.childrenStatus,ud_toungue: inputValue.motherToungue, 
        ud_religion: inputValue.religionID, ud_caste: inputValue.casteID,ud_subcaste: inputValue.subCasteID,ud_pattam_other_details: data.ud_pattam_other_details, 
        ud_country: inputValue.countryID, ud_state: inputValue.stateID, ud_city: inputValue.cityID,ud_city_other: data.ud_city_other,ud_mobile: data.ud_mobile, 
        ud_address: data.addresss, ud_height: inputValue.height, ud_about: '',ud_horoscope_pic:picture.file,raasi: inputValue.rasi,
        nakshatra: inputValue.star,lugnum: inputValue.laknum,birthTime: data.birthTime,birthPlace: data.birthPlace,
        munglikDosham: inputValue.hasOwnProperty('ud_manglik') ? inputValue.ud_manglik : 0,
        thisaiIruppu: inputValue.ud_dhisa,thisaDay: inputValue.thisaDay,thisaMonth: inputValue.thisaMonth,thisaYear: inputValue.thisaYear,
        rKattam1: rasiSelect.rasi_1.join(' '),rKattam2: rasiSelect.rasi_2.join(' '),rKattam3: rasiSelect.rasi_3.join(' '),rKattam4: rasiSelect.rasi_4.join(' '),rKattam5: rasiSelect.rasi_5.join(' '),rKattam6: rasiSelect.rasi_6.join(' '),
        rKattam7: rasiSelect.rasi_7.join(' '),rKattam8: rasiSelect.rasi_8.join(' '),rKattam9: rasiSelect.rasi_9.join(' '),rKattam10: rasiSelect.rasi_10.join(' '),rKattam11: rasiSelect.rasi_11.join(' '),rKattam12: rasiSelect.rasi_12.join(' '),
         nKattam1: amsamSelect.amsam_1.join(' '),nKattam2: amsamSelect.amsam_2.join(' '),nKattam3: amsamSelect.amsam_3.join(' '),nKattam4: amsamSelect.amsam_4.join(' '),nKattam5: amsamSelect.amsam_5.join(' '),nKattam6: amsamSelect.amsam_6.join(' '),
        nKattam7: amsamSelect.amsam_7.join(' '),nKattam8: amsamSelect.amsam_8.join(' '),nKattam9: amsamSelect.amsam_9.join(' '),nKattam10: amsamSelect.amsam_10.join(' '),nKattam11: amsamSelect.amsam_11.join(' '),nKattam12: amsamSelect.amsam_12.join(' ')
        },{ headers: { "content-type": "multipart/form-data" },
        }).then((response)=>{
        setPicture({...picture,name:null});
        if(response.data.error===true){
          setError("ud_email", { type: "validate" });
          setInputErrors(previousState => { return {...previousState, email : 0} });
          toast.error(response.data.message);
        }else{
          navigate('/registertwo',{state:{title:true,message:response.data.message}});
        }
      }).catch((err) => {
        console.log(err);
      })
    }else{
      setInputErrors(previousState => { return {...previousState, password : 1} });
    }
  };


  const onChangePicture = (e) => {
    if (e.target.files[0]){
      var name = e.target.files[0].name;
      var type = e.target.files[0].name;
      if(type){
        var ext = type.split('.');
        if(ext[1]==='jpg' || ext[1]==='jpeg' || ext[1]==='png' || ext[1]==='pdf'){
          setInputErrors({...inputErrors,horoscopePic:""});
        }else setInputErrors({...inputErrors,horoscopePic:0});
      } 
      if(name.length>10){
        var salt = name.split('.');
        salt[0] = name.substring(0,10);
        name = salt[0]+'...'+salt[1];
      }
      setPicture({...picture,file:e.target.files[0],name:name});
    }
  }



  const handleChangeDate = (date) => {
    setDateOfBirth(date);
    setInputErrors(previousState => { return {...previousState, dateOfBirth : 1} });
  }
  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmpasswordInput(e.target.value);
    setInputErrors(previousState => { return {...previousState, password : 0} });
  }

  const togglePassword = () => {
    if(passwordType==="password") setPasswordType("text");
    else  setPasswordType("password")
  }
  
  const toggleConfirmPassword = () => {
    if(confirmpasswordType==="password") setConfirmpasswordType("text");
    else  setConfirmpasswordType("password")
  }

  if(issubmit){
    if(inputValue.countryCodeID===0) setInputErrors(previousState => { return {...previousState, countrycode : 0} });
    if(inputValue.profileCreatedBy===0) setInputErrors(previousState => { return {...previousState, profilecreatedfor : 0} });
    if(inputValue.martialStatus===0) setInputErrors(previousState => { return {...previousState, martialStatus : 0} });
    if(inputValue.gender===0) setInputErrors(previousState => { return {...previousState, gender : 0} });
    if(inputValue.motherToungue===0) setInputErrors(previousState => { return {...previousState, motherToungue : 0} });
    if(inputValue.religionID===0) setInputErrors(previousState => { return {...previousState, religion : 0} });
    if(inputValue.casteID===0) setInputErrors(previousState => { return {...previousState, caste : 0} });
    // if(inputValue.subCasteID===0) setInputErrors(previousState => { return {...previousState, subcaste : 0} });
    if(inputValue.countryID===0) setInputErrors(previousState => { return {...previousState, country : 0} });
    if(inputValue.stateID===0) setInputErrors(previousState => { return {...previousState, state : 0} });
    // if(inputValue.cityID===0) setInputErrors(previousState => { return {...previousState, city : 0} });
    if(inputValue.height===0) setInputErrors(previousState => { return {...previousState, height : 0} });
    if(moment(new Date(dateOfBirth)).format('DD/MM/YYYY')===moment(new Date()).format('DD/MM/YYYY')){
        setInputErrors(previousState => { return {...previousState, dateOfBirth : 0} });
    }else{
        setInputErrors(previousState => { return {...previousState, dateOfBirth : 1} });
    }
    setIsSubmit(0);
  }

  function genstar(value,name,number){
    let arr = [],updateArr = [];
    var rasi  = 'rasi_'+number;
    if(number!==''){
      if(rasiSelect[rasi].length>0) updateArr = rasiSelect[rasi];
    }
    for (let i=1;i<13;i++){
      const dynamicRasi = 'rasi_'+i;
      if(rasiSelect[dynamicRasi].includes(value)){   
        setRasiSelect(previousState => { return {...previousState, [dynamicRasi] : rasiSelect[dynamicRasi].filter((item) => item !== value)} });
      }
    }
    if(updateArr.length===0) arr.push(value);
    else if(!updateArr.includes(value))arr = updateArr.push(value);
    if(number!=='')
      setRasiSelect(previousState => { return {...previousState, [rasi] : rasiSelect[rasi].length===0 ? arr : updateArr} });
    }

  const showRasi = (value) =>{
    let split = '';
    if(rasiSelect[value].length>=0){
      split = rasiSelect[value].join(" ");
      return split;
    }else{
      return 0;
    }
    
  }

  function genamsam(value,name,number){
    let arr = [],updateArr = [];
    var amsam  = 'amsam_'+number;
    if(number!==''){
      if(amsamSelect[amsam].length>0) updateArr = amsamSelect[amsam];
    }
    for (let i=1;i<13;i++){
      const dynamicAmsam = 'amsam_'+i;
      
      if(amsamSelect[dynamicAmsam].includes(value)){   
        setAmsamSelect(previousState => { return {...previousState, [dynamicAmsam] : amsamSelect[dynamicAmsam].filter((item) => item !== value)} });
      }
    }
    if(updateArr.length===0) arr.push(value);
    else if(!updateArr.includes(value))arr = updateArr.push(value);
    if(number!=='')
      setAmsamSelect(previousState => { return {...previousState, [amsam] : amsamSelect[amsam].length===0 ? arr : updateArr} });
    }

  const showAmsam = (value) =>{
    let split = '';
    if(amsamSelect[value].length>=0){
      split = amsamSelect[value].join(" ");
      return split;
    }else{
      return 0;
    }
    
  }


  function handleChange(e,input) {
    if(input==="countrycode"){
      setInputValues(previousState => { return {...previousState, countryCodeID : e.value}});
      setInputErrors(previousState => { return {...previousState, countrycode : 1} });
    }
    if(input==="profilecreatedby"){
      setInputValues(previousState => { return {...previousState, profileCreatedBy :e.value}});
      setInputErrors(previousState => { return {...previousState, profilecreatedfor : 1} });
    }
    if(input==="gender"){
      setInputValues(previousState => { return {...previousState,gender:e.value} });
      setInputErrors(previousState => { return {...previousState, gender :1} });
    }
    if(input==="martialStatus"){
      setInputValues(previousState => { return {...previousState,martialStatus:e.value} });
      setInputErrors(previousState => { return {...previousState, martialStatus : 1} });
    }
    if(input==="noofchildren"){
      setInputValues(previousState => { return {...previousState,noofChildren:e.value} });
    }
    if(input==="childrenstatus"){
      setInputValues(previousState => { return {...previousState,childrenStatus:e.value} }) 
    }
    if(input==="mothertoungue"){
       setInputValues(previousState => { return {...previousState,motherToungue:e.value}})
       setInputErrors(previousState => { return {...previousState, motherToungue : 1} });
    }
    // if(input==="subcaste"){
    //   setInputValues(previousState => { return {...previousState,subCasteID: e.value}});
    //   setInputErrors(previousState => { return {...previousState, subcaste : 1} });
    // }
    // if(input==="city"){
    //   setInputValues(previousState => { return {...previousState,cityID:e.value}});
    //   setInputErrors(previousState => { return {...previousState, city : 1} });
    // }

    if(input==="height"){
       setInputValues(previousState => { return {...previousState,height:e.value}})
      setInputErrors(previousState => { return {...previousState, height : 1} });
    }

    if(input==="rasi"){
      setInputValues(previousState => { return {...previousState,rasi:e.value}})
      
    }

    if(input==="star"){
      setInputValues(previousState => { return {...previousState,star:e.value}})
      
    }

    if(input==="laknum"){
      setInputValues(previousState => { return {...previousState,laknum:e.value}})
      
    }

    if(input==="dhosa"){
      setInputValues(previousState => { return {...previousState,dhosa:e.value}})
      
    }

    if(input==="ud_manglik"){
      setInputValues(previousState => { return {...previousState,ud_manglik:e.value}})
      
    }

    if(input==="ud_dhisa"){
      setInputValues(previousState => { return {...previousState,ud_dhisa:e.value}}) 
    }

    if(input==="thisaDay"){
      setInputValues(previousState => { return {...previousState,thisaDay:e.value}}) 
    }
    if(input==="thisaMonth"){
      setInputValues(previousState => { return {...previousState,thisaMonth:e.value}})
    }
    if(input==="thisaYear"){
      setInputValues(previousState => { return {...previousState,thisaYear:e.value}}) 
    }
  }
 


  function handleReligionChange(e) {
    if(e.value!==''){
      setDatas(previousState => { return {...previousState,caste:[],subcaste:[]}});
      setInputValues(previousState => { return {...previousState, religionID : e.value,casteID : ""} });
      setInputErrors(previousState => { return {...previousState, religion : 1} });
      Axios.get(Constants.API_URL+"religion/"+e.value+"/0/0").then((response)=>{
        if(response.data.data){
          setDatas(previousState => { return {...previousState, caste : response.data.data} });
        }
      });
    }
  }

  function handleCasteChange(e) {
    if(e.value!==''){
      setInputValues(previousState => { return {...previousState,casteID :e.value} });
      setInputErrors(previousState => { return {...previousState,caste : 1} });
      Axios.get(Constants.API_URL+"religion/"+inputValue.religionID+"/"+e.value+"/0").then((response)=>{
        if(response.data.data){
          setDatas(previousState => { return {...previousState, subcaste : response.data.data} });
        }
      });
    }
  }

  function handleCountryChange(e) {
    if(e.value!==''){ 
      setDatas(previousState => { return {...previousState,state:[],city:[]}});
      setInputErrors(previousState => { return {...previousState, country : 1} });
      setInputValues(previousState => { return {...previousState, countryID : e.value} });
      Axios.get(Constants.API_URL+"location/"+e.value+"/0/0").then((response)=>{
        if(response.data.data){
            setDatas(previousState => { return {...previousState, state : response.data.data} });
        }
      });
    }
  }

  function handleStateChange(e) {
    if(e.value!==''){ 
      setInputErrors(previousState => { return {...previousState, state : 1} });
      setInputValues(previousState => { return {...previousState, stateID : e.value} });
      Axios.get(Constants.API_URL+"location/"+inputValue.countryID+"/"+e.value+"/0").then((response)=>{
        if(response.data.data){
          setDatas(previousState => { return {...previousState, city : response.data.data} });
        }
      });
    }
  }

  
  





  return (
    <div className="container mt-5">
    <ToastContainer />
			<div className="row">
				<div className="col-xl-10 offset-xl-1 col-12">
					<div className="gtRegMain">
						<h3 className="gtPageTitle">{t('create_your_profile')}</h3>
						<p className="gtPageSubTitle">{t('create_your_profile_description')}</p>
						<form onSubmit={handleSubmit(onSubmit)}>
							<p>
								<b >
									<span className="text-danger">*</span> 
									{t('all_fields_are_mandatory')}
								</b>
							</p>
							<div className="form-group row">
								<div className="col-xl-4  col-md-4">
									<label >{t('first_name')}<span className="required">*</span>
									</label>          
								</div>
			          <div className="col-xl-8 col-md-8">
			           	<input type="text" className="form-control" placeholder={t('enter_your_first_name')} 
                    {...register("ud_firstname", 
                      {
                        required: true, 
                        pattern: /^[A-Za-z\s]*$/,
                        maxLength: 32, 
                      }
                    )} />
                    {errors.ud_firstname?.type==="required" && <p className="error">{t('ud_firstname_is_required')}</p>}
            				{errors.ud_firstname?.type==="maxLength" && <p className="error">{t('ud_firstname_is_maxlength')}</p>}                 	
            				{errors.ud_firstname?.type==="pattern" && <p className="error">{t('ud_firstname_is_invalid')}</p>}                 	
			          </div>
			        </div>
			        <div className="form-group row">
			          <div className="col-xl-4  col-md-4">
			            <label >{t('last_name')}<span className="required">*</span>
			            </label>          
			          </div>
			          <div className="col-xl-8 col-md-8">
				          <input type="text" className="form-control" placeholder={t('enter_your_last_name')} 
	                    {...register("ud_lastname", 
	                      {
	                        required: true, 
	                        pattern: /^[A-Za-z\s]*$/,
	                        maxLength: 32, 
	                      }
	                    )} />
                  {errors.ud_lastname?.type==="required" && <p className="error">{t('ud_lastname_is_required')}</p>}
          				{errors.ud_lastname?.type==="maxLength" && <p className="error">{t('ud_lastname_is_maxlength')}</p>}                 	
          				{errors.ud_lastname?.type==="pattern" && <p className="error">{t('ud_lastname_is_invalid')}</p>}                 	
			          </div>
			        </div>
			        <div className="form-group row">
			          <div className="col-xl-4  col-md-4">
			            <label >{t('email')}<span className="required">*</span>
			            </label>          
			          </div>
			          <div className="col-xl-8 col-md-8">
			            <input placeholder={t('enter_your_email_address')} className="form-control"
			             {...register("ud_email", 
                    {
                      required: true, 
                      pattern: {
												value : /^\S+@\S+$/i,
											}
                    }
                  )}  />     
			          	<i className="fa fa-envelope fa_eml2" id="fa_eml"></i>
                  {errors.ud_email?.type==="required" && <p className="error">{t('email_is_required')}</p>}
          				{errors.ud_email?.type==="pattern" && <p className="error">{t('email_is_invalid')}</p>}                 	          
          				{errors.ud_email?.type==="validate" && <p className="error">{t('email_is_validate')}</p>}                 	          
			          </div>
			        </div>
			        <div className="form-group row">
			          <div className="col-xl-4  col-md-4">
			            <label >{t('password')}<span className="required">*</span>
			            </label>          
			          </div>
			          <div className="col-xl-8 col-md-8">
			            <input placeholder={t('enter_your_password')} type={passwordType} className='form-control' value={passwordInput}
			            {...register("ud_password", 
                    {
                      required: true, 
                      onChange: (e) => handlePasswordChange(e),
                      minLength: 3, 
                      maxLength: 15, 
                    }
                  )} /> 
                  <span className="2ndfaeyeslash" onClick={togglePassword}>
                    {passwordType==="password"? <i className="fa fa-eye-slash reg_one fa_eyeslashone" id="reg-one"></i> : <i className="fa fa-eye fa_eyeopen reg_one"></i> }
                  </span>
                  {errors.ud_password?.type==="required" && <p className="error">{t('password_is_required')}</p>}
                  {errors.ud_password?.type==="minLength" && <p className="error">{t('password_is_minlength')}</p>}
                  {errors.ud_password?.type==="maxLength" && <p className="error">{t('password_is_maxlength')}</p>}
			          </div>
			        </div>
			        <div className="form-group row">
			          <div className="col-xl-4  col-md-4">
			            <label >{t('repeat_password')}<span className="required">*</span>
			            </label>          
			          </div>
			          <div className="col-xl-8 col-md-8">
			            <input  placeholder={t('enter_your_confirm_password')} type={confirmpasswordType}  className="form-control"  value={confirmpasswordInput}
			             {...register("ud_password_repeat", 
                    {
                      required: true, 
                      onChange: (e) => handleConfirmPasswordChange(e),
                      minLength: 3, 
                      maxLength: 15, 
                    }
                  )} />      
                  <span className="2ndfaeyeslash" onClick={toggleConfirmPassword}>
                    {confirmpasswordType==="password"? <i className="fa fa-eye-slash reg_one fa_eyeslashone" id="reg-one"></i> : <i className="fa fa-eye fa_eyeopen reg_one"></i> }
                  </span> 
                  {errors.ud_password_repeat?.type==="required" && <p className="error">{t('confirm_password_is_required')}</p>}
                  {errors.ud_password_repeat?.type==="minLength" && <p className="error">{t('confirm_password_is_minlength')}</p>}
                  {errors.ud_password_repeat?.type==="maxLength" && <p className="error">{t('confirm_password_is_maxlength')}</p>}
                  {inputErrors.password===1 && <p className="error">{t('confirm_password_must_be_same')}</p>}              
			          </div>
			        </div>
			        <div className="form-group row">
			          <div className="col-xl-4  col-md-4">
			            <label className=" required">{t('phone_no')}<span className="required">*</span></label>          
			          </div>
				        <div className="col-xl-8 col-md-8">
				          <div className="row">
				            <div className="col-5" style={{'paddingRight':'0px'}}>
				              <Select  onChange={(e) => handleChange(e,"countrycode")} 
				              options = {getCountryCode} defaultValue={Constants.prompt_code}>
				              </Select>    
				            </div>
				            <div className="col-7" style={{'paddingLeft':'0px'}}>
				              <input  className="form-control" readOnly="readOnly" type="text" value={mobile} />
				              <i className="fas fa-mobile-alt fa_mob fa_alt" id="fa_mob" ></i>
				            </div>
				            <div style={{'paddingLeft':'15px'}}>
				            </div>
				                       
				          </div>
				          {inputErrors.countrycode===0 && <p className="error">{t('country_code_cannot_be_blank')}</p>}
				        </div>
				    	</div>
				    	<div className="form-group row">
								<div className="col-xl-4  col-md-4">
									<label className=" required">{t('profile_created_by')}<span className="required">*</span>
									</label>         
								</div>
								<div className="col-xl-8 col-md-8">
									<Select  options = {Constants.getProfileCreatedFor} defaultValue={Constants.prompt}
									onChange={(e) => handleChange(e,"profilecreatedby") } >
				          </Select>                                        
				          {inputErrors.profilecreatedfor===0 && <span className="error">{t('profilecreatedby_cannot_be_blank')}</span>}         
								</div>
		          </div>
		          <div className="form-group row">
								<div className="col-xl-4  col-md-4">
									<label className=" required">{t('gender')}<span className="required">*</span>
									</label>         
								</div>
								<div className="col-xl-8 col-md-8">
									<Select  options={Constants.getUserGender}  defaultValue={Constants.prompt}
									onChange={(e) => handleChange(e,"gender") } >
									</Select>          
									{inputErrors.gender===0 && <span className="error">{t('gender_cannot_be_blank')}</span>}                                         
								</div>
		          </div>
			        <div className="form-group row">
								<div className="col-xl-4  col-md-4">
									<label className=" required">{t('date_of_birth')}<span className="required">*</span></label>                
								</div>
								<div className="col-xl-8  col-md-8">
                      <DatePicker
                      dateFormat="dd-MM-yyyy"
                      className="col-md-12 form-control"
                      selected={dateOfBirth}
                      placeholderText={t('enter_the_dob')}
                      onChange={(date) => handleChangeDate(date)}
                      />
									{/* <DatePicker format="DD-MM-YYYY" className="col-md-12  form-control" selected={dateOfBirth} placeholderText={t('enter_the_dob')} onChange={(date) => handleChangeDate(date)} />  */}
									<p className="disply-no" style={{'marginTop':'5px','position':'absolute','right':'30px','fontSize':'12px'}} >eg: 21/11/2000 (DD/MM/YYYY)</p>
									{inputErrors.dateOfBirth===0 && <p className="error">{t('dob_is_invalid')}</p>}               
								</div>
		          </div>
							<div className="form-group row">
								<div className="col-xl-4  col-md-4">
									<label className=" required">{t('martial_status')}<span className="required">*</span></label>       
								</div>
								<div className="col-xl-8 col-md-8">
									<Select  options={Constants.getMartialStatus}  defaultValue={Constants.prompt}
									onChange={(e) =>  handleChange(e,"martialStatus") } >
									</Select>                                                   
									{inputErrors.martialStatus===0 && <span className="error">{t('martial_status_cannot_be_blank')}</span>}                                         
								</div>
							</div>
              <div style={{ display: inputValue.martialStatus === 1 ? 'none' : 'block' }}>
							<div className="form-group row" id="dis_child" >
								<div className="col-xl-4 col-md-4">
									<label >{t('no_of_children')}</label>               
								</div>
								<div className="col-xl-8 col-md-8">
									<Select  options={Constants.getUserNoofChildren} defaultValue={Constants.prompt}
									onChange={(e) => handleChange(e,"noofchildren") } >
									</Select>                                               
									</div>
								</div>
								<div className="form-group row" id="dis_child_status">
									<div className="col-xl-4  col-md-4">
										<label >{t('children_status')}</label>                  
									</div>
									<div className="col-xl-8 col-md-8">
										<Select  options={Constants.getUserChildrenStatus} defaultValue={Constants.prompt}
										onChange={(e) => handleChange(e,"childrenstatus") } >
										</Select>
									</div>
								</div>
                </div>
								<div className="form-group row">
									<div className="col-xl-4  col-md-4">
										<label className=" required">{t('mother_toungue')}<span className="required">*</span></label>               
									</div>
									<div className="col-xl-8 col-md-8">
										<Select  options={getMotherToungueData} defaultValue={Constants.prompt}
										onChange={(e) => handleChange(e,"mothertoungue") } >
										</Select>      
										{inputErrors.motherToungue===0 && <span className="error">{t('mother_toungue_cannot_be_blank')}</span>}                                         
									</div>
								</div>
		            <div className="form-group row">
									<div className="col-xl-4  col-md-4">
											<label className=" required">{t('religion')} <span className="required">*</span></label>             
									</div>
									<div className="col-xl-8 col-md-8">
										 <Select  options={getReligionData} defaultValue={Constants.prompt}
										 onChange={(e) => handleReligionChange(e)} >
										</Select>      
										{inputErrors.religion===0 && <span className="error">{t('religion_cannot_be_blank')}</span>}                                         
									</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4  col-md-4">
										<label className=" required">{t('caste')} <span className="required">*</span></label>               
									</div>
									<div className="col-xl-8 col-md-8">
										<Select  options={datas.caste} value={inputValue.casteID!==null ? datas.caste.filter(obj => obj.value === inputValue.casteID) : ''}
										 placeholder={t('select_any_one')} defaultValue={Constants.prompt}
										 onChange={(e) => handleCasteChange(e)} >
										</Select>      
										{inputErrors.caste===0 && <span className="error">{t('caste_cannot_be_blank')}</span>}                                         
										</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4  col-md-4">
										<label className=" required">{t('sub_caste')}<span className="required">*</span></label>               
									</div>
									<div className="col-xl-8 col-md-8">
                  
                  <input type="text" className="form-control" placeholder={t('enter_your_first_name')} 
                    {...register("ud_pattam_other_details", 
                      {
                        required: true, 
                        pattern: /^[A-Za-z\s]*$/,
                        maxLength: 32, 
                      }
                    )} />
                    
										
										{/* {inputErrors.subcaste===0 && <span className="error">{t('subcaste_cannot_be_blank')}</span>}                           */}
										</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4  col-md-4">
										<label className=" required" >{t('country')}<span className="required">*</span></label>               
									</div>
									<div className="col-xl-8 col-md-8">
									   <Select  options={getCountryData} defaultValue={Constants.prompt}
										 onChange={(e) => handleCountryChange(e)} >
										</Select>      
										{inputErrors.country===0 && <span className="error">{t('country_cannot_be_blank')}</span>}                                                                  
									</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4  col-md-4">
										<label className=" required">{t('state')}<span className="required">*</span></label>               
									</div>
									<div className="col-xl-8 col-md-8">
										 <Select  options={datas.state}
										 value={inputValue.stateID!==null ? datas.state.filter(obj => obj.value === inputValue.stateID) : ''}
										 placeholder={t('select_any_one')} defaultValue={Constants.prompt}
										 onChange={(e) =>  handleStateChange(e)} >
										</Select>      
										{inputErrors.state===0 && <span className="error">{t('state_cannot_be_blank')}</span>}                                         
										</div>
								</div>
								<div className="form-group row">
									<div className="col-xl-4  col-md-4">
										<label className=" required" >{t('city')}<span className="required">*</span></label>               
									</div>
									<div className="col-xl-8 col-md-8 col-12">
                  <input type="text" className="form-control" placeholder={t('enter_your_first_name')} 
                    {...register("ud_city_other", 
                      {
                        required: true, 
                        pattern: /^[A-Za-z\s]*$/,
                        maxLength: 32, 
                      }
                    )} />
										     
										{/* {inputErrors.city===0 && <span className="error">{t('city_cannot_be_blank')}</span>}                                               */}
										</div>
								</div>
								<div className="form-group row">
                  <div className="col-xl-4 col-md-4">
                    <label className=" required">{t('height')}<span className="required">*</span></label>               
                  </div>
                  <div className="col-xl-8 col-md-8">
                    <Select  maxMenuHeight={150}  options={Constants.getHeightAsc} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"height")} >
                    </Select>      
                    {inputErrors.height===0 && <span className="error">{t('height_cannot_be_blank')}</span>}                                     
                  </div>
                </div>
								<div className="form-group row">
									<div className="col-xl-4  col-md-4">
										<label >{t('address')}<span className="required">*</span>
										</label>          
									</div>
									<div className="col-xl-8 col-md-8">
										<input placeholder={t('enter_your_addresss')} className="form-control " 
										{...register("addresss", 
                      {
                        required: true, 
                        maxLength: 100, 
                      }
                    )} />               
										{errors.addresss?.type==="required" && <p className='error'>{t('address_is_required')}</p>}						         
										{errors.addresss?.type==="maxLength" && <p className='error'>{t('address_is_invalid')}</p>}						         
									</div>
								</div>
                <div className="form-group row">
                  <div className="col-xl-4  col-md-4">
                    <label >{t('alternative_mobile')}
                    </label>          
                  </div>
                  <div className="col-xl-8 col-md-8">
                    <input placeholder={t('enter_your_alternative_mobile')} className="form-control " 
                      {...register("ud_mobile", 
                        {
                          minLength: 10, 
                          maxLength: 10, 
                          pattern:/^[0-9+-]+$/,
                          onChange: (e) => setValue('ud_mobile',e.target.value.replace(/\D/g,''))
                        }
                      )} />      
                    {errors.ud_mobile?.type==="pattern" && <p className='error'>{t('mobile_is_invalid')}</p>}                    
                    {errors.ud_mobile?.type==="minLength" && <p className='error'>{t('mobile_is_minlength')}</p>}                    
                    {errors.ud_mobile?.type==="maxLength" && <p className='error'>{t('mobile_is_maxlength')}</p>}                    
                  </div>
                </div>
                
                <div className="form-group row">
                  <div className="col-xl-4 col-md-4">
                    <label className=" required">Rasi (Moon sign)</label>               
                  </div>
                  <div className="col-xl-8 col-md-8">
                    <Select   options={Constants.getUserRasiMoonSign} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"rasi")} >
                    </Select>      
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xl-4 col-md-3">
                    <label className=" required">Star (Nakshatra)</label>               
                  </div>
                  <div className="col-xl-8 col-md-3">
                    <Select   options={Constants.getUserStarNakshatra} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"star")} >
                    </Select>      
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xl-4 col-md-3">
                    <label className=" required">Lagnum</label>               
                  </div>
                  <div className="col-xl-8 col-md-3">
                    <Select  options={Constants.getUserRasiMoonSign} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"laknum")} >
                    </Select>      
                    {inputErrors.height===0 && <span className="error">{t('height_cannot_be_blank')}</span>}                                     
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xl-4  col-md-4">
                    <label >Birth Time
                    </label>          
                  </div>
                  <div className="col-xl-8 col-md-8">
                    <input type='time' placeholder="Enter The Birth Time" className="form-control"
                      {...register("birthTime")}  />     
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xl-4  col-md-4">
                    <label >Birth Place
                    </label>          
                  </div>
                  <div className="col-xl-8 col-md-8">
                    <input type='input' placeholder="Enter The Birth Pace" className="form-control"
                      {...register("birthPlace")}  />     
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xl-4 col-md-3">
                    <label className=" required">Dhosa</label>               
                  </div>
                  <div className="col-xl-8 col-md-3">
                    <Select options={Constants.getUserDhosa} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"dhosa")} >
                    </Select>      
                  </div>
                </div>
                {inputValue.dhosa===2 &&
                <div className="form-group row">
                  <div className="col-xl-4 col-md-3">
                    <label className=" required">Manglik Dhosam</label>               
                  </div>
                  <div className="col-xl-8 col-md-3">
                    <Select options={Constants.getManglikDhosam} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"ud_manglik")} >
                    </Select>      
                  </div>
                </div>}
                <div className="form-group row">
                  <div className="col-xl-4 col-md-3">
                    <label className=" required">Thisai irruppu</label>               
                  </div>
                  <div className="col-xl-8 col-md-3">
                    <Select  options={Constants.getThisaiIruppu} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"ud_dhisa")} >
                    </Select>      
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xl-4  col-md-4">
                    <label >Dhisa Day
                    </label>          
                  </div>
                  <div className="col-xl-8 col-md-8">
                  <Select  options={Constants.getDay} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"thisaDay")} >
                    </Select>               
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xl-4  col-md-4">
                    <label >Dhisa Month
                    </label>          
                  </div>
                  <div className="col-xl-8 col-md-8">
                    <Select  options={Constants.getMonth} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"thisaMonth")} >
                    </Select>   
                                              
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xl-4  col-md-4">
                    <label >Dhisa Year
                    </label>          
                  </div>
                  <div className="col-xl-8 col-md-8">
                    <Select  options={Constants.getYear} defaultValue={Constants.prompt}
                     onChange={(e) => handleChange(e,"thisaYear")} >
                    </Select>             
                  </div>
                </div>
                  <div className="form-group row">
                    <div className='col-xl-8'>
                      <div className='row'>
                        <div className='col-xl-2'>
                        <label className="lang-ta">yf;fdk;</label>
                        <Select options={Constants.getUserZodiacNO}   title="Choose lagnam" name="rasi_select1" id="rasi_select1" onChange={(e) => genstar('y','rasi_1',e.value)} >
                        </Select>
                        </div>
                        <div className='col-xl-2'>
                        <label className="lang-ta">#hpad;</label>
                        <Select options={Constants.getUserZodiacNO}  name="rasi_select2" title="Choose Suriyan" id="rasi_select2" onChange={(e) => genstar('#','rasi_2',e.value)} >
                        </Select>
                        </div>
                        <div className='col-xl-2'>
                        <label className="lang-ta">re;jpud;</label>
                        <Select options={Constants.getUserZodiacNO} name="rasi_select3" title="Choose Chandran" id="rasi_select3" onChange={(e) => genstar('re;','rasi_3',e.value)} >
                        </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">nrt;tha;</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select4" title="Choose Sevvai" id="rasi_select4" onChange={(e) => genstar('nr','rasi_4',e.value)} >
                          </Select>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-xl-2'>
                          <label className="lang-ta">Gjd;</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select5" title="Choose Budhan" id="rasi_select5" onChange={(e) => genstar('G','rasi_5',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">FU</label>
                          <Select options={Constants.getUserZodiacNO} name="rasi_select6" title="Choose Guru" id="rasi_select6" onChange={(e) => genstar('F','rasi_6',e.value)} >
                           </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">Rf;fpud;</label>
                          <Select options={Constants.getUserZodiacNO} name="rasi_select7" title="Choose Sukran" id="rasi_select7" onChange={(e) => genstar('R','rasi_7',e.value)} >
                           </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">rdp</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select8" title="Choose sani" id="rasi_select8" onChange={(e) => genstar('rdp','rasi_8',e.value)} >
                          </Select>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-xl-2'>
                          <label className="lang-ta">uhF</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select9" title="Choose Rahu" id="rasi_select9" onChange={(e) =>genstar('uh','rasi_9',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">NfJ</label>
                          <Select options={Constants.getUserZodiacNO}  name="rasi_select10" title="Choose Kedhu" id="rasi_select10" onChange={(e) => genstar('Nf','rasi_10',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">khe;jp</label>
                          <Select options={Constants.getUserZodiacNO} name="rasi_select11" title="Choose Mandhi" id="rasi_select11" onChange={(e) => genstar('kh','rasi_11',e.value)} >
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-4'>
                              <table width="288" height="260" border="0" align="center" cellPadding="0" cellSpacing="0">
                              <tbody>
                                <tr className="table-design lang-ta">
                                  <td width="70" height="70" className="table-design lang-ta " id="rasi_box12" style={{'background':`#fff ${Constants.rasi12_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_12"style={{'color':'red'}}>
                                      {showRasi('rasi_12')}
                                        </span> 
                                      <input  id="rasi_txt_12" name="ud_p_r12" type="hidden" value={showRasi('rasi_12')} {...register("ud_p_r12")}/>
                                  </td>
                                  <td width="70" height="70" className="table-design lang-ta " id="rasi_box1" style={{'background':`#fff ${Constants.rasi1_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_1" style={{'color':'red'}}>{showRasi('rasi_1')}</span> 
                                      <input  id="rasi_txt_1" name="ud_p_r1" type="hidden" value={showRasi('rasi_1')} {...register("ud_p_r1")} />
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box2" style={{'background':`#fff ${Constants.rasi2_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_2" style={{'color':'red'}}>{showRasi('rasi_2')}</span> 
                                    <input  id="rasi_txt_2" name="ud_p_r2" type="hidden" value={showRasi('rasi_2')} {...register("ud_p_r2")} />
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box3" style={{'background':`#fff ${Constants.rasi3_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_3" style={{'color':'red'}}>{showRasi('rasi_3')}</span> 
                                    <input  id="rasi_txt_3" name="ud_p_r3" type="hidden" value={showRasi('rasi_3')} {...register("ud_p_r3")} />
                                </td>
                                </tr>
                                <tr>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box11" style={{'background':`#fff ${Constants.rasi11_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_11" style={{'color':'red'}}>{showRasi('rasi_11')}</span> 
                                    <input  id="rasi_txt_11" name="ud_p_r11" type="hidden" value={showRasi('rasi_11')} {...register("ud_p_r11")} />
                                  </td>
                                  <td colSpan="2" rowSpan="2" align="center" valign="middle" style={{'verticalAlign':'middle','textAlign':'center','backgroundColor':'#FFF' ,'border':'#CCC solid 1px'}}>
                                    <tr width="142" border="0" align="center">
                                      
                                        <td width="155" height="115" bgcolor="#FFFF00"><div align="center"><span className="lang-ta">uhrp</span></div></td>
                                      
                                    </tr>
                                  </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box4" style={{'background':`#fff ${Constants.rasi4_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                    <span className="rasi" id="rasi_4" style={{'color':'red'}}>{showRasi('rasi_4')}</span>
                                    <input  id="rasi_txt_4" name="ud_p_r4" type="hidden" value={showRasi('rasi_4')} {...register("ud_p_r4")} />
                                  </td>
                                </tr>
                                <tr>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box10" style={{'background':`#fff ${Constants.rasi10_Url.one} no-repeat center`,'border':'1px solid #000'}}> 
                                      <span className="rasi" id="rasi_10" style={{'color':'red'}}>{showRasi('rasi_10')}</span> 
                                      <input  id="rasi_txt_10" name="ud_p_r10" type="hidden" value={showRasi('rasi_10')} {...register("ud_p_r10")} />
                                      </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box5" style={{'background':`#fff ${Constants.rasi5_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_5" style={{'color':'red'}}>{showRasi('rasi_5')}</span> 
                                      <input  id="rasi_txt_5" name="ud_p_r5" type="hidden" value={showRasi('rasi_5')} {...register("ud_p_r5")} />
                                    </td>
                                </tr>
                                <tr>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box9" style={{'background':`#fff ${Constants.rasi9_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_9" style={{'color':'red'}}>{showRasi('rasi_9')}</span> 
                                      <input  id="rasi_txt_9" name="ud_p_r9" type="hidden" value={showRasi('rasi_9')} {...register("ud_p_r9")}/>
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box8" style={{'background':`#fff ${Constants.rasi8_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_8" style={{'color':'red'}}>{showRasi('rasi_8')}</span>
                                      <input  id="rasi_txt_8" name="ud_p_r8" type="hidden" value={showRasi('rasi_8')} {...register("ud_p_r8")}/>
                                    </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box7" style={{'background':`#fff ${Constants.rasi7_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_7" style={{'color':'red'}}>{showRasi('rasi_7')}</span>
                                      <input  id="rasi_txt_7" name="ud_p_r7" type="hidden" value={showRasi('rasi_7')} {...register("ud_p_r7")}/>
                                  </td>
                                  <td width="70" height="70" className="table-design lang-ta" id="rasi_box6" style={{'background':`#fff ${Constants.rasi6_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                                      <span className="rasi" id="rasi_6" style={{'color':'red'}}>{showRasi('rasi_6')}</span>
                                      <input  id="rasi_txt_6" name="ud_p_r6" type="hidden" value={showRasi('rasi_6')} {...register("ud_p_r6")}/>
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className='col-xl-8'>
                      <div className='row'>
                        <div className='col-xl-2'>
                          <label className="lang-ta">yf;fdk;</label>
                          <Select options={Constants.getUserZodiacNO}   title="Choose lagnam" name="amsam_select1" id="amsam_select1" onChange={(e) => genamsam('y','amsam_select1',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">#hpad;</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select2" title="Choose Suriyan" id="amsam_select2" onChange={(e) => genamsam('#','amsam_select2',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">re;jpud;</label>
                          <Select options={Constants.getUserZodiacNO} name="amsam_select3" title="Choose Chandran" id="amsam_select3" onChange={(e) => genamsam('re;','amsam_select3',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">nrt;tha;</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select4" title="Choose Sevvai" id="amsam_select4" onChange={(e) => genamsam('nr','amsam_select4',e.value)} >
                          </Select>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-xl-2'>
                          <label className="lang-ta">Gjd;</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select5" title="Choose Budhan" id="amsam_select5" onChange={(e) =>genamsam('G','amsam_select5',e.value)} >
                          </Select> 
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">FU</label>
                          <Select options={Constants.getUserZodiacNO} name="amsam_select6" title="Choose Guru" id="amsam_select6" onChange={(e) =>genamsam('F','amsam_select6',e.value)} >
                           </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">Rf;fpud;</label>
                          <Select options={Constants.getUserZodiacNO} name="amsam_select7" title="Choose Sukran" id="amsam_select7" onChange={(e) => genamsam('R','amsam_select7',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">rdp</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select8" title="Choose sani" id="amsam_select8" onChange={(e) => genamsam('rdp','amsam_select8',e.value)} >
                          </Select>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-xl-2'>
                          <label className="lang-ta">uhF</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select9" title="Choose Rahu" id="amsam_select9" onChange={(e) => genamsam('uh','amsam_select9',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">NfJ</label>
                          <Select options={Constants.getUserZodiacNO}  name="amsam_select10" title="Choose Kedhu" id="amsam_select10" onChange={(e) => genamsam('Nf','amsam_select10',e.value)} >
                          </Select>
                        </div>
                        <div className='col-xl-2'>
                          <label className="lang-ta">khe;jp</label>
                          <Select options={Constants.getUserZodiacNO} name="amsam_select11" title="Choose Mandhi" id="amsam_select11" onChange={(e) => genamsam('kh','amsam_select11',e.value)} >
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-4'>
                        <table width="288" height="260" border="0" align="center" cellPadding="0" cellSpacing="0">
                        <tbody>
                          <tr className="table-design lang-ta">
                            <td width="70" height="70" className="table-design lang-ta " id="rasi_box12" style={{'background':`#fff ${Constants.rasi12_Url.one} no-repeat center`,'border':'1px solid #000'}}> 
                              <span className="rasi" id="amsam_12" style={{'color':'red'}}>{showAmsam('amsam_12')}</span> 
                              <input  id="amsam_txt_12" name="ud_p_a12"  value={showAmsam('amsam_12')} type="hidden" {...register("ud_p_a12")} />
                            </td>
                            <td width="70" height="70" className="table-design lang-ta " id="rasi_box1" style={{'background':`#fff ${Constants.rasi1_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_1" style={{'color':'red'}}>{showAmsam('amsam_1')}</span>
                              <input  id="amsam_txt_1" name="ud_p_a1"  value={showAmsam('amsam_1')} type="hidden" {...register("ud_p_a1")}/>
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box2" style={{'background':`#fff ${Constants.rasi2_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_2" style={{'color':'red'}}>{showAmsam('amsam_2')}</span>
                              <input  id="amsam_txt_2" name="ud_p_a2"  value={showAmsam('amsam_2')} type="hidden" {...register("ud_p_a2")}/>
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box3" style={{'background':`#fff ${Constants.rasi3_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_3" style={{'color':'red'}}>{showAmsam('amsam_3')}</span>
                              <input  id="amsam_txt_3" name="ud_p_a3"  value={showAmsam('amsam_3')}  type="hidden" {...register("ud_p_a3")} />  
                          </td>
                          </tr>
                          <tr>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box11" style={{'background':`#fff ${Constants.rasi11_Url.one} no-repeat center`,'border':'1px solid #000'}}> 
                            <span className="rasi" id="amsam_11" style={{'color':'red'}}>{showAmsam('amsam_11')}</span> 
                            <input  id="amsam_txt_11" name="ud_p_a11"  value={showAmsam('amsam_11')} type="hidden" {...register("ud_p_a11")}/>
                              </td>
                            <td colSpan="2" rowSpan="2" align="center" valign="middle" style={{'verticalAlign':'middle', 'textAlign':'center', 'backgroundColor':'#FFF','border':'#CCC solid 1px'}}>
                              {/* <tr width="142" border="0" align="center"> */}
                                
                                  <th width="155" height="115" bgcolor="#FFFF00"><div align="center"><span className="lang-ta">etk;rk;</span></div></th>
                                
                              {/* </tr> */}
                            </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box4" style={{'background':`#fff ${Constants.rasi4_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_4" style={{'color':'red'}}>{showAmsam('amsam_4')}</span>
                              <input  id="amsam_txt_4" name="ud_p_a4"  value={showAmsam('amsam_4')} type="hidden" {...register("ud_p_a4")}/>
                            </td>
                          </tr>
                          <tr>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box10" style={{'background':`#fff ${Constants.rasi10_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_10" style={{'color':'red'}}>{showAmsam('amsam_10')}</span>
                              <input  id="amsam_txt_10" name="ud_p_a10"  value={showAmsam('amsam_10')} type="hidden" {...register("ud_p_a10")} />
                                </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box5" style={{'background':`#fff ${Constants.rasi5_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_5" style={{'color':'red'}}>{showAmsam('amsam_5')}</span>
                              <input  id="amsam_txt_5" name="ud_p_a5"  value={showAmsam('amsam_5')} type="hidden" {...register("ud_p_a5")}/>
                              </td>
                          </tr>
                          <tr>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box9" style={{'background':`#fff ${Constants.rasi9_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_9" style={{'color':'red'}}>{showAmsam('amsam_9')}</span>
                              <input  id="amsam_txt_9" name="ud_p_a9"  value={showAmsam('amsam_9')} type="hidden" {...register("ud_p_a9")}/>   
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box8" style={{'background':`#fff ${Constants.rasi8_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_8" style={{'color':'red'}}>{showAmsam('amsam_8')}</span> 
                              <input  id="amsam_txt_8" name="ud_p_a8"  value={showAmsam('amsam_8')} type="hidden" {...register("ud_p_a8")}/>
                              </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box7" style={{'background':`#fff ${Constants.rasi7_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_7" style={{'color':'red'}}>{showAmsam('amsam_7')}</span>
                              <input  id="amsam_txt_7" name="ud_p_a7"  value={showAmsam('amsam_7')} type="hidden" {...register("ud_p_a7")}/>
                            </td>
                            <td width="70" height="70" className="table-design lang-ta" id="rasi_box6" style={{'background':`#fff ${Constants.rasi6_Url.one} no-repeat center`,'border':'1px solid #000'}}>
                              <span className="rasi" id="amsam_6" style={{'color':'red'}}>{showAmsam('amsam_6')}</span>
                              <input  id="amsam_txt_6" name="ud_p_a6"  value={showAmsam('amsam_6')} type="hidden" {...register("ud_p_a6")}/>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                  </div>
                
                
                
								<div className="row mt-4">
									<div className="col-xl-4 offset-xl-4">
										<div className="row">
											<div className="col-xl-12">
												<input onClick={(e) => setIsSubmit(1)} style={{'backgroundColor':'#dfbb26'}} className="btn btnRegFclear btn-block" type="submit" name="yt0" value="Submit" />                    
											</div>
										</div>
									</div>
								</div> 
			  			</form>
			  		</div>
			  	</div>
			  </div>
			</div>
		)
}