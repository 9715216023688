import React, { useEffect,useState } from 'react'
import { Link,useNavigate,useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Constants from "../services/Constants";
import Axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RegisterComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputerrors,setInputerrors]  = useState(0);
  const { register, handleSubmit,setValue, formState: { errors } } = useForm();
  const location = useLocation();
  const state = location.state;
  const onSubmit = (data) => {
    Axios.post(Constants.API_URL+"register",{
      ud_phone:data.username
      }).then((response)=>{
          setInputerrors(0);
          if(response.data.data.id=== 0){
            setInputerrors(data.username);
          }else{
            localStorage.setItem("registerotp", parseInt(response.data.data.otp));
			console.log(response.data.data.otp);
            localStorage.setItem("registerid", parseInt(response.data.data.id));
            localStorage.setItem("registermobile", parseInt(data.username));
            if(Constants.VERIFY) navigate('/verify',{state:{title:true,message:t('otp_sent_success')}});
            else navigate('/registerone',{state:{title:true,message:t('user_verify_success')}});
          } 
      }).catch((err) => {
        console.log(err);
      })
  };

  const handleLogin = (e) => {
    setValue('username',e.target.value.replace(/\D/g,''));
    setInputerrors(0);
  }

  useEffect(() => {
      if(state!==null){
        var title = location.state.title;
        if(title===true){
          if(location.state.message!==""){
            if(location.state.error===true) toast.error(location.state.message);
            else toast.success(location.state.message);
          }
          navigate(location.pathname, { replace: true });
        } 
      }
  },[location,navigate,state])


  return (
      <div className="container mt-5">
      <ToastContainer />
        <div className="row">
          <div className="col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
            <div className="gtLogMain">
              <h4 className="gtPageTitle">{t('enter_mobile_no')}</h4>
              <p className="gtPageSubTitle">{t('enter_valid_mobile_no')}</p>
              <p className="gtPageSubTitle"></p>
              <div className="col-xl-8 col-lg-8 col-md-12 offset-lg-2">
              <form onSubmit={handleSubmit(onSubmit)} id="signup-form" className="form-horizontal" encType="multipart/form-data">
                <div className="row">
                  <div className="form-group col-12">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t('india_phonecode')}</span>
                      </div> 
                      <input type="text" className="form-control"  placeholder={t('enter_the_mobile_no')} 
                        {...register("username", 
                          {
                            required: true, 
                            onChange: (e) => handleLogin(e) ,
                            maxLength: 10,
                            minLength: 10,
                          }
                        )} />
                      <i className="fas fa-mobile-alt fa_mob1"></i>
                    </div>
                    {errors.username?.type==="required" && <p className="error">{t('mobile_is_required')}</p>}
                    {errors.username?.type==="maxLength" && <p className="error">{t('mobile_is_maxlength')}</p>}
                    {errors.username?.type==="minLength" && <p className="error">{t('mobile_is_minlength')}</p>}
                    {inputerrors!==0 && <p className="error">{t('phone_no')} "{inputerrors}" {t('has_already_taken')}</p>}
                  </div>
                  <div className="form-group col-12 text-center">
                    <button className='btn btnLogMain btn-block'>Submit</button>
                    <div className="gtBorderOr"><span>{t('or')}</span></div>
                      <div className="form-group text-center gtSignModalTxt mt-4">
                        <Link to="/login" state={{state:{title:false,message:''}}} className="btn btnSignUpCall mt-2 btn-block">{t('login')}</Link>
                      </div>
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}